import React, { useState, useContext, useRef, useEffect } from 'react';
import MaterialTable, {
  Column,
  Options,
  MaterialTableProps,
  Query,
  QueryResult,
} from 'material-table';
import { Link as RouterLink } from 'react-router-dom';
import {
  Check,
  Edit,
  Assessment,
  Dashboard,
  FileCopy,
} from '@material-ui/icons';
import store from 'stores';
import linkGroupSlice from 'stores/slices/link_group';
import { ILinkGroup } from 'models/link_group';
import tableIcons from 'components/molecules/MaterialTableIcons';
import { IconButton, Link } from '@material-ui/core';
import { check } from 'helpers/rbac';
import EditLinkDialog from 'components/organisms/pages/Links/EditLinkDialog';
import theme from 'themes';
import { TableRefContext } from 'components/pages/Links';
import { convertQuery } from 'helpers/pagination';
import Axios from 'axios';
import { IPagination } from 'models/pagination';
import useBooleanState from 'helpers/hooks/use_boolean_state';
import { usePromise, useCopyToClipboard } from 'react-use';
import useDashboardCookie from 'helpers/hooks/use_dashbord_cookie';
import notificationSlice from 'stores/slices/notification';

const LinksTable = () => {
  const mounted = usePromise();
  const canManageLinks = check('manage-links');
  const [dialog, open, close] = useBooleanState(false);
  const [editLink, setEditLink] = useState<ILinkGroup>({
    id: 0,
    user_id: 0,
    site_id: 0,
    remove_referrer: false,
    copy_link: '',
    code: '',
    links: [],
  });
  const tableRef = useRef<MaterialTableProps<ILinkGroup>>();
  const refContext = useContext(TableRefContext);
  const [, copyToClipboard] = useCopyToClipboard();
  const { add } = useDashboardCookie();

  const onEditClicked = (linkGroup: ILinkGroup) => {
    setEditLink(linkGroup);
    open();
  };

  const onCopyClicked = (link: string) => {
    copyToClipboard(link);

    store.dispatch(
      notificationSlice.actions.set({
        content: 'リンクをクリップボードにコピーしました',
        severity: 'success',
      })
    );
  };

  const addDashboardItem = (id: number) => {
    add(id);

    store.dispatch(
      notificationSlice.actions.set({
        content: 'ダッシュボードに追加しました',
        severity: 'success',
      })
    );
  };

  const columns: Column<ILinkGroup>[] = [
    {
      title: 'サイト',
      field: 'site_id',
      render: (rowData) => rowData.site?.url,
      searchable: true,
    },
    {
      title: 'コード',
      field: 'code',
      searchable: true,
    },
    {
      title: 'リダイレクトURL',
      field: 'links',
      render: (rowData) => {
        return rowData.links?.find((link) => link.device_id === null)?.url.url;
      },
    },
    {
      title: 'リファラカット',
      field: 'remove_referrer',
      sorting: false,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => (rowData.remove_referrer ? <Check /> : null),
    },
    {
      title: '集計',
      sorting: false,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => {
        return (
          <Link to={`/links/${rowData.id}`} component={RouterLink}>
            <IconButton color="primary" size="small" aria-label="aggregate">
              <Assessment />
            </IconButton>
          </Link>
        );
      },
    },
    {
      title: 'アクション',
      sorting: false,
      cellStyle: {
        textAlign: 'end',
      },
      headerStyle: {
        textAlign: 'end',
      },
      render: (rowData) => {
        return (
          <>
            <IconButton
              size="small"
              aria-label="copy"
              onClick={() => {
                onCopyClicked(rowData.copy_link);
              }}
            >
              <FileCopy />
            </IconButton>
            <IconButton
              disabled={!canManageLinks}
              size="small"
              aria-label="edit"
              onClick={() => {
                onEditClicked(rowData);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              size="small"
              aria-label="addDashboard"
              onClick={() => {
                addDashboardItem(rowData.id);
              }}
            >
              <Dashboard />
            </IconButton>
          </>
        );
      },
    },
  ];

  const options: Options = {
    showTitle: false,
    search: true,
    pageSize: 10,
    rowStyle: (data, index) => {
      if (index % 2 === 0) {
        return { backgroundColor: theme.palette.background.default };
      }
      return {};
    },
    draggable: false,
  };

  const fetchData = async (
    query: Query<ILinkGroup>
  ): Promise<QueryResult<ILinkGroup>> => {
    try {
      store.dispatch(
        linkGroupSlice.actions.setQuery(JSON.parse(JSON.stringify(query)))
      );
      const convertedQuery = convertQuery(query);
      const response = await mounted(
        Axios.get<IPagination<ILinkGroup>>('/link', {
          params: convertedQuery,
        })
      );

      return {
        data: response.data.data,
        page: response.data.current_page - 1,
        totalCount: response.data.total,
      };
    } catch {
      return { data: [], page: 0, totalCount: 0 };
    }
  };

  useEffect(() => {
    if (tableRef.current) {
      refContext.setRef(tableRef);
    }
  }, [refContext]);

  return (
    <>
      <EditLinkDialog open={dialog} onClose={close} link={editLink} />

      <MaterialTable
        icons={tableIcons}
        columns={columns}
        data={fetchData}
        options={options}
        tableRef={tableRef}
      />
    </>
  );
};

export default LinksTable;
