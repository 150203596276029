import React, { useState, useEffect } from 'react';
import MaterialTable, { Column, Options } from 'material-table';
import tableIcons from 'components/molecules/MaterialTableIcons';
import { IDeviceGroupAggregate } from 'models/log_aggregate';
import { useSelector } from 'react-redux';
import { RootState } from 'stores';
import dayjs from 'dayjs';
import theme from 'themes';

interface logAggregateLogData {
  date: string;
  weekDay: {
    title: string;
    day: number;
  };
  total: number | undefined;
  device_groups: IDeviceGroupAggregate[] | undefined;
}
interface props {
  className?: string;
}

const LogAggregateTable = ({ className }: props) => {
  const logAggregate = useSelector(
    (state: RootState) => state.log_aggregate.data
  );
  const deviceGroups = useSelector(
    (state: RootState) => state.device_group.data
  );
  const _deviceGroups = [{ id: 0, name: 'PC' }, ...deviceGroups];
  const [data, setData] = useState<logAggregateLogData[]>([]);

  const columns: Column<logAggregateLogData>[] = [
    {
      title: '日付',
      field: 'date',
    },
    {
      title: '曜日',
      field: 'weekDay.title',
      customSort: (a, b) => {
        return a.weekDay.day - b.weekDay.day;
      },
    },
    {
      title: 'トータル',
      customSort: (a, b) => {
        const aValue = a.total ? a.total : 0;
        const bValue = b.total ? b.total : 0;

        return aValue - bValue;
      },
      render: (rowData) => {
        return rowData.total ? rowData.total : 0;
      },
    },
    ..._deviceGroups.map((deviceGroup) => {
      return {
        title: deviceGroup.name,
        customSort: (a: logAggregateLogData, b: logAggregateLogData) => {
          const aData = logAggregate.logs[a.date];
          const bData = logAggregate.logs[b.date];
          const aSearched = aData?.device_groups.find(
            (device_group) => device_group.name === deviceGroup.name
          );
          const bSearched = bData?.device_groups.find(
            (device_group) => device_group.name === deviceGroup.name
          );

          const aValue = aSearched ? aSearched.total : 0;
          const bValue = bSearched ? bSearched.total : 0;

          return aValue - bValue;
        },
        render: (rowData: logAggregateLogData) => {
          const log = logAggregate.logs[rowData.date];
          const searched = log?.device_groups.find(
            (device_group) => device_group.name === deviceGroup.name
          );

          return searched ? searched.total : 0;
        },
      };
    }),
  ];

  const options: Options = {
    paging: false,
    maxBodyHeight: 450,
    showTitle: false,
    toolbar: false,
    rowStyle: (data, index) => {
      if (index % 2 === 0) {
        return { backgroundColor: theme.palette.background.default };
      }
      return {};
    },
    draggable: false,
  };

  useEffect(() => {
    if (!logAggregate.from || !logAggregate.to) {
      return;
    }

    const data: logAggregateLogData[] = [];
    const from = dayjs(logAggregate.from);
    const to = dayjs(logAggregate.to);
    for (
      let i = to;
      i.isAfter(from.subtract(1, 'day'));
      i = i.subtract(1, 'day')
    ) {
      const date = i.format('YYYY-MM-DD');
      const weekDay = {
        title: i.format('ddd'),
        day: i.day(),
      };
      const total = logAggregate.logs[date]?.total;
      const device_groups = logAggregate.logs[date]?.device_groups;
      data.push({
        date,
        weekDay,
        total,
        device_groups,
      });
    }

    setData(data);
  }, [logAggregate.from, logAggregate.to, logAggregate.logs]);

  return (
    <div className={className}>
      <MaterialTable
        icons={tableIcons}
        options={options}
        columns={columns}
        data={data}
      />
    </div>
  );
};

export default LogAggregateTable;
