import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import LogsTable from 'components/organisms/pages/Logs/LogsTable';
import { check } from 'helpers/rbac';
import { Redirect } from 'react-router-dom';
import { AuthRoutes } from 'models/route';
import { MaterialTableProps } from 'material-table';
import { ILog } from 'models/log';
import store from 'stores';
import { getDevices } from 'stores/slices/device';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

export const TableRefContext = React.createContext({
  ref: React.createRef<MaterialTableProps<ILog> | undefined>(),
  setRef: (
    ref: React.MutableRefObject<MaterialTableProps<ILog> | undefined>
  ) => {},
});

const Logs = () => {
  const canShowConfig = check('show-config');
  const classes = useStyles();
  const [tableRef, setTableRef] = useState<
    React.MutableRefObject<MaterialTableProps<ILog> | undefined>
  >(useRef());

  if (!check('show-logs')) {
    return (
      <Redirect
        to={{
          pathname: AuthRoutes.index,
        }}
      />
    );
  }

  // created
  if (canShowConfig) {
    store.dispatch(getDevices());
  }

  return (
    <TableRefContext.Provider value={{ ref: tableRef, setRef: setTableRef }}>
      <div className={classes.root}>
        <div className={classes.content}>
          <LogsTable />
        </div>
      </div>
    </TableRefContext.Provider>
  );
};

export default Logs;
