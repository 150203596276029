import store from 'stores';

export const check = (action: string) => {
  const abilities = store.getState().auth.user.role?.abilities;

  if (!abilities) {
    return false;
  }

  let flag = false;

  abilities.some((ability) => {
    if (ability.name === action) {
      flag = true;
      return true;
    }
    return false;
  });

  return flag;
};
