import React, { FormEvent, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useSelector } from 'react-redux';
import store, { RootState } from 'stores';
import { authorize } from 'stores/slices/auth';
import notificationSlice from 'stores/slices/notification';
import { NavLink } from 'react-router-dom';
import { Typography, Link } from '@material-ui/core';
import useRouter from 'helpers/hooks/use_rooter';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginTop: theme.spacing(3),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  resetLink: {
    marginRight: theme.spacing(1),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const error = useSelector((state: RootState) => state.error);
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const response = await store.dispatch(authorize(email, password));
    if (response) {
      store.dispatch(
        notificationSlice.actions.set({
          content: 'Success!',
          severity: 'success',
        })
      );
      router.push('/');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Typography className={classes.title} variant="h2">
          ログイン
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={error.errors?.email ? true : false}
            helperText={error.errors?.email ?? ''}
            margin="normal"
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={error.errors?.password ? true : false}
            helperText={error.errors?.password ?? ''}
            margin="normal"
            fullWidth
            name="password"
            label="パスワード"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            ログイン
          </Button>
          <Typography color="textSecondary" variant="body2">
            パスワードを
            <Link
              className={classes.resetLink}
              component={NavLink}
              to="/reset-password"
              variant="body1"
            >
              忘れましたか？
            </Link>
          </Typography>
        </form>
      </div>
    </Container>
  );
}
