import React from 'react';
import {
  Card,
  Paper,
  CardContent,
  Grid,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  Theme,
  createStyles,
  IconButton,
  CardActions,
  makeStyles,
  Link,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useAsync } from 'react-use';
import Axios from 'axios';
import { ILogAggregate } from 'models/log_aggregate';
import {
  Delete,
  DragHandle as DragHandleIcon,
  Assessment,
} from '@material-ui/icons';
import { SortableHandle } from 'react-sortable-hoc';
import dayjs from 'dayjs';
import { ILinkGroup } from 'models/link_group';

const useStyles = makeStyles((theme) => ({
  cardActions: {
    justifyContent: 'space-between',
  },
}));

const DragHandle = SortableHandle(() => (
  <IconButton>
    <DragHandleIcon />
  </IconButton>
));

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  })
)(TableRow);

interface Props {
  className?: string;
  id: number;
  onRemove: (id: number) => void;
}

const DashboardItem = ({ className, id, onRemove }: Props) => {
  const classes = useStyles();
  const logsPromise = useAsync(
    async () => await Axios.get<ILogAggregate>(`/log/${id}`)
  );

  const linkGroupPromise = useAsync(
    async () => await Axios.get<ILinkGroup>(`/link/${id}`)
  );

  const onDeleteClicked = () => {
    onRemove(id);
  };

  if (
    logsPromise.loading ||
    !logsPromise.value ||
    linkGroupPromise.loading ||
    !linkGroupPromise.value
  ) {
    return (
      <Paper className={className}>
        <CardActions className={classes.cardActions}>
          <DragHandle />

          <IconButton
            onClick={() => {
              onDeleteClicked();
            }}
          >
            <Delete />
          </IconButton>
        </CardActions>
      </Paper>
    );
  }

  const logAggregate = logsPromise.value.data;
  const linkGroup = linkGroupPromise.value.data;

  return (
    <Card className={className}>
      <CardActions className={classes.cardActions}>
        <DragHandle />

        <Link to={`/links/${linkGroup.id}`} component={RouterLink}>
          <IconButton color="primary" size="small" aria-label="aggregate">
            <Assessment />
          </IconButton>
        </Link>
        <IconButton
          onClick={() => {
            onDeleteClicked();
          }}
        >
          <Delete />
        </IconButton>
      </CardActions>
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={6}>
            <TextField
              inputProps={{ readOnly: true }}
              label="URL"
              defaultValue={linkGroup.site?.url}
            ></TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              inputProps={{ readOnly: true }}
              label="コード"
              defaultValue={linkGroup.code}
            ></TextField>
          </Grid>
        </Grid>
      </CardContent>

      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>日付</TableCell>
              <TableCell>曜日</TableCell>
              <TableCell>トータル</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.keys(logAggregate.logs).map((key) => {
              const log = logAggregate.logs[key];
              const day = dayjs(key);

              return (
                <StyledTableRow key={key}>
                  <TableCell>{day.format('YYYY-MM-DD')}</TableCell>
                  <TableCell>{day.format('ddd')}</TableCell>
                  <TableCell>{log?.total}</TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default DashboardItem;
