import { useCookie } from 'react-use';
import arrayMove from 'array-move';

const useDashboardCookie = () => {
  const [ids, updateIds, deleteIds] = useCookie('link_group_ids');

  const _ids = ids?.split(',') ?? [];

  const add = (id: number) => {
    if (id === 0) {
      return;
    }
    const _ids = ids?.split(',') ?? [];
    const found = _ids.findIndex((_id) => _id === id.toString());
    if (found >= 0) {
      updateIds(arrayMove(_ids, found, 0).join(','));
    } else {
      updateIds([..._ids, id].join(','), {
        expires: 1000,
      });
    }
  };

  const remove = (id: number) => {
    const _ids = ids?.split(',') ?? [];
    const deletedIds = _ids.filter((_id) => {
      return id !== Number(_id);
    });

    if (deletedIds.length > 0) {
      updateIds(deletedIds.join(','));
    } else {
      deleteIds();
    }
  };

  return { ids: _ids, updateIds, deleteIds, add, remove };
};

export default useDashboardCookie;
