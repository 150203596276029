import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  makeStyles,
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import logo from 'assets/img/logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    width: 40,
    display: 'block',
  },
}));

interface Props {
  className?: string;
  onSidebarOpen?: () => void;
}

const Header = ({ className, onSidebarOpen }: Props) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="secondary">
      <Toolbar>
        <RouterLink to="/">
          <img className={classes.logo} alt="Logo" src={logo} />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
