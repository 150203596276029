import React, { FormEvent, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useSelector } from 'react-redux';
import store, { RootState } from 'stores';
import { InvitationAuthentication } from 'stores/slices/auth';
import notificationSlice from 'stores/slices/notification';
import { Typography } from '@material-ui/core';
import queryString from 'query-string';
import { IInvitation } from 'models/invitation';
import Axios from 'axios';
import useRouter from 'helpers/hooks/use_rooter';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginTop: theme.spacing(3),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignIn = () => {
  const classes = useStyles();
  const error = useSelector((state: RootState) => state.error);
  const rooter = useRouter();
  const query = queryString.parse(window.location.search);
  const [email, setEmail] = useState('');
  const [token, setToken] = useState(
    typeof query.token === 'string' ? query.token : ''
  );
  const [password, setPassword] = useState('');

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const response = await store.dispatch(
      InvitationAuthentication(token, password)
    );
    if (response) {
      store.dispatch(
        notificationSlice.actions.set({
          content: 'Success!',
          severity: 'success',
        })
      );
      rooter.push('/');
    }
  };

  useEffect(() => {
    if (!token) {
      return;
    }
    (async () => {
      const response = await Axios.get<IInvitation>('/invitation/join', {
        params: {
          token,
        },
      });
      setEmail(response.data.email);
    })();
  }, [token]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Typography className={classes.title} variant="h2">
          Join
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            value={email}
            disabled
            onChange={(e) => setEmail(e.target.value)}
            error={error.errors?.email ? true : false}
            helperText={error.errors?.email ?? ''}
            margin="normal"
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
          />
          <TextField
            value={token}
            disabled
            onChange={(e) => setToken(e.target.value)}
            error={error.errors?.token ? true : false}
            helperText={error.errors?.token ?? ''}
            margin="normal"
            fullWidth
            id="token"
            label="token"
            name="token"
            autoComplete="token"
          />
          <TextField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={error.errors?.password ? true : false}
            helperText={error.errors?.password ?? ''}
            margin="normal"
            fullWidth
            name="password"
            label="パスワード"
            type="password"
            id="password"
            autoComplete="current-password"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            join
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default SignIn;
