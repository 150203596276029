import React from 'react';
import { RouteProps, Route, Redirect } from 'react-router-dom';
import { RootState } from 'stores';
import { useSelector } from 'react-redux';

interface Props extends RouteProps {
  requireAuth: boolean;
  redirectPath: string;
}

const AuthRoute = ({ requireAuth, redirectPath, children, ...rest }: Props) => {
  const isAuthed = useSelector((state: RootState) => state.auth.isAuthed);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (requireAuth === isAuthed) {
          return children;
        }

        return (
          <Redirect
            to={{ pathname: redirectPath, state: { from: location } }}
          />
        );
      }}
    />
  );
};

export default AuthRoute;
