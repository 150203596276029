import React, { useState } from 'react';
import {
  Paper,
  ListItemIcon,
  ListItem,
  ListItemText,
  List,
} from '@material-ui/core';
import { Delete, Edit, DragHandle as DragHandleIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import store, { RootState } from 'stores';
import EditDeviceDialog from 'components/organisms/pages/Devices/EditDeviceDialog';
import Axios from 'axios';
import { IDevice } from 'models/device';
import deviceSlice from 'stores/slices/device';
import {
  SortEndHandler,
  SortableHandle,
  SortableElement,
  SortableContainer,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import useBooleanState from 'helpers/hooks/use_boolean_state';

const DragHandle = SortableHandle(() => (
  <ListItemIcon>
    <DragHandleIcon />
  </ListItemIcon>
));

interface SortableItemProps {
  device: IDevice;
  onEditClicked: (
    id: number,
    name: string,
    regex: string,
    device_group_id: number | undefined
  ) => void;
  onDeleteClicked: (id: number) => void;
}

const SortableItem = SortableElement(
  ({ device, onEditClicked, onDeleteClicked }: SortableItemProps) => (
    <ListItem ContainerComponent="div">
      <DragHandle />
      <ListItemText
        primary={device.name}
        secondary={device.device_group?.name}
      />
      <ListItemText primary={device.regex} />
      <ListItemIcon
        aria-label="edit"
        onClick={() => {
          onEditClicked(
            device.id,
            device.name,
            device.regex,
            device.device_group?.id
          );
        }}
      >
        <Edit />
      </ListItemIcon>
      <ListItemIcon
        aria-label="delete"
        onClick={() => {
          onDeleteClicked(device.id);
        }}
      >
        <Delete />
      </ListItemIcon>
    </ListItem>
  )
);

interface SortableListContainerProps {
  children: React.ReactNode;
}

const SortableListContainer = SortableContainer(
  ({ children }: SortableListContainerProps) => (
    <List component={Paper}>{children}</List>
  )
);

const CustomizedTables = () => {
  const devices = useSelector((state: RootState) => state.device.data);
  const [dialog, open, close] = useBooleanState(false);
  const [id, setId] = useState(0);
  const [name, setName] = useState('');
  const [regex, setRegex] = useState('');
  const [deviceGroupId, setDeviceGroupId] = useState(0);

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const items = arrayMove(devices, oldIndex, newIndex);
    store.dispatch(deviceSlice.actions.setData(items));

    const order = items.map((device) => device.id);
    Axios.post('/device/order', { order });
  };

  const onEditClicked = (
    id: number,
    name: string,
    regex: string,
    device_group_id: number | undefined
  ) => {
    const _device_group_id = device_group_id ?? 0;
    setId(id);
    setName(name);
    setRegex(regex);
    setDeviceGroupId(_device_group_id);

    open();
  };

  const onDeleteClicked = async (id: number) => {
    const isConfirm = window.confirm('削除');
    if (!isConfirm) {
      return;
    }

    const response = await Axios.delete<IDevice[]>(`/device/${id}`);
    if (response) {
      store.dispatch(deviceSlice.actions.setData(response.data));
    }
  };

  return (
    <>
      <EditDeviceDialog
        open={dialog}
        onClose={close}
        id={id}
        name={name}
        regex={regex}
        device_group_id={deviceGroupId}
      />

      <SortableListContainer
        onSortEnd={onSortEnd}
        useDragHandle={true}
        lockAxis="y"
      >
        {devices.map((item, index) => (
          <SortableItem
            key={item.id}
            index={index}
            device={item}
            onEditClicked={onEditClicked}
            onDeleteClicked={onDeleteClicked}
          ></SortableItem>
        ))}
      </SortableListContainer>
    </>
  );
};

export default CustomizedTables;
