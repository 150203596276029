import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import authSlice from './slices/auth';
import errorSlice from './slices/error';
import notificationSlice from './slices/notification';
import permissionSlice from './slices/permission';
import siteSlice from './slices/site';
import userSlice from './slices/user';
import deviceSlice from './slices/device';
import deviceGroupSlice from './slices/device_group';
import linkGroupSlice from './slices/link_group';
import logSlice from './slices/log';
import excludedIpSlice from './slices/excluded_ip';
import logAggregateSlice from './slices/log_aggregate';
import referrerAggregateSlice from './slices/referrer_aggregate';

const reducer = combineReducers({
  auth: authSlice.reducer,
  error: errorSlice.reducer,
  notification: notificationSlice.reducer,
  permission: permissionSlice.reducer,
  site: siteSlice.reducer,
  user: userSlice.reducer,
  device: deviceSlice.reducer,
  device_group: deviceGroupSlice.reducer,
  link_group: linkGroupSlice.reducer,
  log: logSlice.reducer,
  excluded_ip: excludedIpSlice.reducer,
  log_aggregate: logAggregateSlice.reducer,
  referrer_aggregate: referrerAggregateSlice.reducer,
});

const store = configureStore({ reducer });

export type RootState = ReturnType<typeof reducer>;
export type AppThunk<T = void> = ThunkAction<
  T,
  RootState,
  null,
  Action<string>
>;

export default store;
