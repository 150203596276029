import React, { useEffect } from 'react';
import store, { RootState } from 'stores';
import { useSelector } from 'react-redux';
import { getReferrerAggregate } from 'stores/slices/referrer_aggregate';
import MaterialTable, { Column, Options } from 'material-table';
import { IReferrer } from 'models/referrer_aggregate';
import theme from 'themes';
import tableIcons from 'components/molecules/MaterialTableIcons';

interface props {
  className?: string;
  id: string | undefined;
}

const LogAggregateTable = ({ className, id }: props) => {
  const referrerAggregate = useSelector(
    (state: RootState) => state.referrer_aggregate.data
  );
  const logAggregate = useSelector(
    (state: RootState) => state.log_aggregate.data
  );

  const columns: Column<IReferrer>[] = [
    {
      title: 'リファラー',
      field: 'referrer',
    },
    {
      title: 'トータル',
      field: 'total',
    },
  ];
  const options: Options = {
    paging: false,
    maxBodyHeight: 250,
    showTitle: false,
    toolbar: false,
    rowStyle: (data, index) => {
      if (index % 2 === 0) {
        return { backgroundColor: theme.palette.background.default };
      }
      return {};
    },
    draggable: false,
  };

  useEffect(() => {
    if (!logAggregate.from || !logAggregate.to) {
      return;
    }
    try {
      (async () => {
        await store.dispatch(
          getReferrerAggregate(Number(id), {
            from: logAggregate.from || undefined,
            to: logAggregate.to || undefined,
          })
        );
      })();
    } catch {}
  }, [id, logAggregate.from, logAggregate.to]);

  return (
    <div className={className}>
      <MaterialTable
        icons={tableIcons}
        options={options}
        columns={columns}
        data={referrerAggregate.referrer
          .filter((ref) => ref.referrer !== '')
          .map((ref) => ({ ...ref }))}
      />
    </div>
  );
};

export default LogAggregateTable;
