import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  TextField,
} from '@material-ui/core';
import store, { RootState } from 'stores';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import { IUser } from 'models/user';
import authSlice from 'stores/slices/auth';
import { DialogProps } from 'models/dialog';

interface Props extends DialogProps {}

const EditDetailDialog = ({ open, onClose }: Props) => {
  const error = useSelector((state: RootState) => state.error);
  const me = useSelector((state: RootState) => state.auth.user);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [editPassword, setEditPassword] = useState('');
  const [editConfirmPassword, setEditConfirmPassword] = useState('');

  const onEditClicked = async () => {
    const response = await Axios.put<IUser>('/oauth/me', {
      email: email,
      current_password: password,
      new_password: editPassword,
      new_password_confirmation: editConfirmPassword,
    });

    if (response) {
      store.dispatch(authSlice.actions.setUserEmail(response.data.email));
      onClose();
    }
  };

  const close = () => {
    onClose();
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    setEmail(me.email);
    setPassword('');
    setEditPassword('');
    setEditConfirmPassword('');
  }, [me.email, open]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      disableEscapeKeyDown
      onClose={onClose}
    >
      <DialogTitle>プロフィール 編集</DialogTitle>

      <DialogContent>
        <TextField
          value={email}
          error={error.errors.email ? true : false}
          helperText={error.errors.email ?? ''}
          onChange={(e) => setEmail(e.target.value)}
          margin="dense"
          id="email"
          label="メールアドレス"
          fullWidth
        ></TextField>

        <TextField
          value={editPassword}
          error={error.errors.new_password ? true : false}
          helperText={error.errors.new_password ?? ''}
          onChange={(e) => setEditPassword(e.target.value)}
          margin="dense"
          id="new_password"
          label="新しいパスワード"
          type="password"
          fullWidth
        ></TextField>

        <TextField
          value={editConfirmPassword}
          error={error.errors.new_password_confirmation ? true : false}
          helperText={error.errors.new_password_confirmation ?? ''}
          onChange={(e) => setEditConfirmPassword(e.target.value)}
          margin="dense"
          id="new_password_confirmation"
          label="新しいパスワード 確認"
          type="password"
          fullWidth
        ></TextField>

        <TextField
          value={password}
          error={error.errors.current_password ? true : false}
          helperText={error.errors.current_password ?? ''}
          onChange={(e) => setPassword(e.target.value)}
          margin="dense"
          id="password"
          label="パスワード"
          type="password"
          fullWidth
        ></TextField>
      </DialogContent>

      <DialogActions>
        <Button onClick={close} color="primary">
          キャンセル
        </Button>
        <Button onClick={onEditClicked} color="primary">
          編集
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDetailDialog;
