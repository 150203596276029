import React, { useState, useEffect, useRef, useContext } from 'react';
import clsx from 'clsx';
import { IconButton, makeStyles } from '@material-ui/core';
import { Edit, Delete, NetworkCheck, GetApp } from '@material-ui/icons';
import store, { RootState } from 'stores';
import EditSiteDialog from 'components/organisms/pages/Sites/EditSiteDialog';
import Axios from 'axios';
import { ISite } from 'models/site';
import siteSlice from 'stores/slices/site';
import MaterialTable, {
  Column,
  Options,
  Query,
  QueryResult,
  MaterialTableProps,
} from 'material-table';
import tableIcons from 'components/molecules/MaterialTableIcons';
import { check } from 'helpers/rbac';
import theme from 'themes';
import { IPagination } from 'models/pagination';
import { convertQuery } from 'helpers/pagination';
import { useSelector } from 'react-redux';
import { TableRefContext } from 'components/pages/Sites';
import useBooleanState from 'helpers/hooks/use_boolean_state';
import { usePromise } from 'react-use';
import notificationSlice from 'stores/slices/notification';

const useStyles = makeStyles((theme) => ({
  healthy: {
    color: theme.palette.info.main,
  },
  unHealth: {
    color: theme.palette.error.main,
  },
}));

const SitesTable = () => {
  const classes = useStyles();
  const mounted = usePromise();
  const query = useSelector((state: RootState) => state.site.query);
  const canManegeSites = check('manage-sites');
  const [dialog, open, close] = useBooleanState(false);
  const [id, setId] = useState(0);
  const [url, setUrl] = useState('');
  const tableRef = useRef<MaterialTableProps<ISite>>();
  const refContext = useContext(TableRefContext);
  const [colors, setColors] = useState<{
    [key: number]: boolean;
  }>({});

  const onEditClicked = (site: ISite) => {
    setId(site.id);
    setUrl(site.url);
    open();
  };

  const onDeleteClicked = async (id: number) => {
    const isConfirm = window.confirm('削除');
    if (!isConfirm) {
      return;
    }

    const response = await Axios.delete<ISite[]>(`/site/${id}`);
    if (response) {
      if (refContext.ref.current?.onQueryChange && query) {
        refContext.ref.current?.onQueryChange(query);
      }
    }
  };

  const onDownloadClicked = async (site: ISite) => {
    const isConfirm = window.confirm('ファイルダウンロード');
    if (!isConfirm) {
      return;
    }

    try {
      const response = await Axios.get<string>(`/site/${site.id}`);

      const link = document.createElement('a');

      const contentDisposition: string =
        response.headers['content-disposition'] || '';
      const fileName =
        contentDisposition
          .split(';')
          .map((v) => v.trim())
          .find((v) => v.toLowerCase().startsWith('filename='))
          ?.split('=')
          ?.pop()
          ?.trim() || 'link.php';
      link.setAttribute('download', fileName);

      const type =
        response.headers['content-type'] || 'application/octet-stream';
      const blob = new Blob([response.data], { type });
      link.href = window.URL.createObjectURL(blob);

      link.click();
    } catch {
      store.dispatch(
        notificationSlice.actions.set({
          content: 'ファイルの取得に失敗しました',
          severity: 'error',
        })
      );
    }
  };

  const columns: Column<ISite>[] = [
    {
      title: 'URL',
      field: 'url',
    },
    {
      title: 'トークン',
      field: 'token',
    },
    {
      title: 'ヘルスチェック',
      sorting: false,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => {
        return (
          <IconButton size="small">
            <NetworkCheck
              className={clsx({
                [classes.healthy]: colors[rowData.id] === true,
                [classes.unHealth]: colors[rowData.id] === false,
              })}
            />
          </IconButton>
        );
      },
    },
    {
      title: 'アクション',
      sorting: false,
      cellStyle: {
        textAlign: 'end',
      },
      headerStyle: {
        textAlign: 'end',
      },
      render: (rowData) => {
        return (
          <>
            <IconButton
              size="small"
              aria-label="download"
              onClick={() => {
                onDownloadClicked(rowData);
              }}
            >
              <GetApp />
            </IconButton>

            <IconButton
              disabled={!canManegeSites}
              size="small"
              aria-label="edit"
              onClick={() => {
                onEditClicked(rowData);
              }}
            >
              <Edit />
            </IconButton>

            <IconButton
              disabled={!canManegeSites}
              size="small"
              aria-label="delete"
              onClick={() => {
                onDeleteClicked(rowData.id);
              }}
            >
              <Delete />
            </IconButton>
          </>
        );
      },
    },
  ];

  const options: Options = {
    showTitle: false,
    toolbar: false,
    pageSize: 10,
    rowStyle: (data, index) => {
      if (index % 2 === 0) {
        return { backgroundColor: theme.palette.background.default };
      }
      return {};
    },
  };

  const fetchData = async (
    query: Query<ISite>
  ): Promise<QueryResult<ISite>> => {
    try {
      store.dispatch(siteSlice.actions.setQuery({ ...query }));
      const convertedQuery = convertQuery(query);
      const response = await mounted(
        Axios.get<IPagination<ISite>>('./site', {
          params: convertedQuery,
        })
      );

      let _colors = { ...colors };
      // eslint-disable-next-line array-callback-return
      response.data.data.map(async (site) => {
        try {
          const value = await mounted(
            Axios.get<{ status: number; message: string }>(
              `/site/healthcheck/${site.id}`
            )
          );
          _colors[site.id] = value.data?.status === 200;
        } catch {
          _colors[site.id] = false;
        }

        setColors({ ...colors, ..._colors });
      });

      return {
        data: response.data.data,
        page: response.data.current_page - 1,
        totalCount: response.data.total,
      };
    } catch {
      return { data: [], page: 0, totalCount: 0 };
    }
  };

  useEffect(() => {
    if (tableRef.current) {
      refContext.setRef(tableRef);
    }
  }, [refContext]);

  return (
    <>
      <EditSiteDialog open={dialog} onClose={close} id={id} url={url} />

      <MaterialTable
        icons={tableIcons}
        columns={columns}
        data={fetchData}
        options={options}
        tableRef={tableRef}
      />
    </>
  );
};

export default SitesTable;
