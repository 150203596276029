import React from 'react';
import { makeStyles } from '@material-ui/core';
import MinimalHeader from 'components/molecules/MinimalHeader';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 64,
    height: '100vh',
  },
  content: {
    height: '100%',
  },
}));

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Minimal = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MinimalHeader />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default Minimal;
