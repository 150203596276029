import React from 'react';
import { makeStyles } from '@material-ui/core';
import UsersToolbar from 'components/organisms/pages/Users/UsersToolbar';
import UsersTable from 'components/organisms/pages/Users/UsersTable';
import store from 'stores';
import { getPermissions } from 'stores/slices/permission';
import { getUsers } from 'stores/slices/user';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const Users = () => {
  const classes = useStyles();

  // created
  store.dispatch(getPermissions());
  store.dispatch(getUsers());

  return (
    <div className={classes.root}>
      <UsersToolbar />
      <div className={classes.content}>
        <UsersTable />
      </div>
    </div>
  );
};

export default Users;
