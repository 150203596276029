import React from 'react';
import { makeStyles } from '@material-ui/core';
import ExcludedIpsToolbar from 'components/organisms/pages/ExcludedIps/ExcludedIpsToolbar';
import ExcludedIpsTable from 'components/organisms/pages/ExcludedIps/ExcludedIpsTable';
import store from 'stores';
import { getIps } from 'stores/slices/excluded_ip';
import { check } from 'helpers/rbac';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const ExcludedIp = () => {
  const classes = useStyles();

  // created
  store.dispatch(getIps());

  return (
    <div className={classes.root}>
      {check('manage-excluded-ips') ? <ExcludedIpsToolbar /> : <></>}

      <div className={classes.content}>
        <ExcludedIpsTable />
      </div>
    </div>
  );
};

export default ExcludedIp;
