import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  makeStyles,
  Input,
  InputAdornment,
  Tooltip,
  IconButton,
  Button,
} from '@material-ui/core';
import { Assignment } from '@material-ui/icons';
import Axios from 'axios';
import { IInvitation } from 'models/invitation';
import { useSelector } from 'react-redux';
import store, { RootState } from 'stores';
import errorSlice from 'stores/slices/error';
import CopyToClipBoard from 'react-copy-to-clipboard';
import { NonAuthRoutes } from 'models/route';
import useBooleanState from 'helpers/hooks/use_boolean_state';
import { DialogProps } from 'models/dialog';

interface Props extends DialogProps {}

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
}));

const InvitationDialog = ({ open, onClose }: Props) => {
  const classes = useStyles();
  const error = useSelector((state: RootState) => state.error);
  const permissions = useSelector((state: RootState) => state.permission.data);
  const [invited, setInvited] = useState(false);
  const [email, setEmail] = useState('');
  const [roleId, setRoleId] = useState(1);
  const [token, setToken] = useState('');
  const [tip, openTip, closeTip] = useBooleanState(false);
  const joinLink = `${window.location.protocol}//${window.location.host}${NonAuthRoutes.join}`;

  const invite = async () => {
    const response = await Axios.post<IInvitation[]>('/invitation', {
      email,
      role_id: roleId,
    });
    if (response) {
      const invitation = response.data.find(
        (invitation) => invitation.email === email
      );
      if (invitation) {
        setToken(invitation.token);
        setInvited(true);
      }
    }
  };

  const close = () => {
    store.dispatch(errorSlice.actions.clear());

    onClose();
  };

  const more = () => {
    setInvited(false);
  };

  useEffect(() => {
    if (open) {
      setEmail('');
      setInvited(false);
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">招待</DialogTitle>

      {!invited && (
        <>
          <DialogContent>
            <TextField
              value={email}
              error={error.errors?.email ? true : false}
              helperText={error.errors?.email ?? ''}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
              margin="dense"
              id="name"
              label="メールアドレス"
              type="email"
              fullWidth
            />
            <FormControl
              className={classes.formControl}
              error={error.errors?.role_id ? true : false}
            >
              <InputLabel>ロール</InputLabel>
              <Select
                error={error.errors?.role_id ? true : false}
                id="role"
                value={roleId}
                onChange={(e) => {
                  const value = e.target.value as number;
                  setRoleId(value);
                }}
              >
                {permissions.map((permission) => (
                  <MenuItem key={permission.id} value={permission.id}>
                    {permission.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{error.errors?.role_id ?? ''}</FormHelperText>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              キャンセル
            </Button>
            <Button onClick={invite} color="primary">
              招待
            </Button>
          </DialogActions>
        </>
      )}

      {invited && (
        <>
          <DialogContent>
            <FormControl fullWidth>
              <Input
                type="text"
                value={`${joinLink}?token=${token}`}
                readOnly
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip
                      arrow
                      open={tip}
                      onClose={closeTip}
                      disableHoverListener
                      placement="top"
                      title="Copied!"
                    >
                      <CopyToClipBoard text={`${joinLink}?token=${token}`}>
                        <IconButton
                          disabled={token === ''}
                          onClick={() => {
                            openTip();
                          }}
                        >
                          <Assignment />
                        </IconButton>
                      </CopyToClipBoard>
                    </Tooltip>
                  </InputAdornment>
                }
              ></Input>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={more} color="primary">
              More
            </Button>
            <Button onClick={close} color="primary">
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default InvitationDialog;
