import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  Button,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getLinkGroups } from 'stores/slices/link_group';
import store, { RootState } from 'stores';
import { DialogProps } from 'models/dialog';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ILinkGroup } from 'models/link_group';
import { useEffectOnce } from 'react-use';

const useStyles = makeStyles((theme) => ({
  itemRoot: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

interface Props extends DialogProps {
  onAdd: (id: number) => void;
}

const AddDashboardItemDialog = ({ open, onClose, onAdd }: Props) => {
  const classes = useStyles();
  const linkGroups = useSelector((state: RootState) => state.link_group.data);
  const [id, setId] = useState(0);

  const addDashboardItem = async () => {
    onAdd(id);

    reset();
    close();
  };

  const close = () => {
    onClose();
  };

  const reset = () => {
    setId(0);
  };

  useEffectOnce(() => {
    store.dispatch(getLinkGroups());
  });

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">追加</DialogTitle>

      <DialogContent>
        <Autocomplete
          id="link_group_id"
          options={linkGroups}
          getOptionLabel={(option) => option.site?.url || ''}
          onChange={(event: any, value: ILinkGroup | null) => {
            setId(value?.id ?? 0);
          }}
          renderInput={(params) => {
            return <TextField {...params} label="リンク" />;
          }}
          renderOption={(option) => (
            <Grid className={classes.itemRoot}>
              <TextField
                inputProps={{ readOnly: true }}
                label="コード"
                defaultValue={option.code}
              ></TextField>
              <TextField
                inputProps={{ readOnly: true }}
                label="URL"
                defaultValue={option.site?.url}
              ></TextField>
            </Grid>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          キャンセル
        </Button>
        <Button onClick={addDashboardItem} color="primary">
          追加
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDashboardItemDialog;
