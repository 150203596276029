import React from 'react';
import { makeStyles, Grid, Paper, IconButton } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import {
  SortableElement,
  SortableContainer,
  SortEnd,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import useBooleanState from 'helpers/hooks/use_boolean_state';
import AddDashboardItemDialog from 'components/organisms/pages/Dashboard/AddDashboardItemDialog';
import DashboardItem from 'components/organisms/pages/Dashboard/DashboardItem';
import useDashboardCookie from 'helpers/hooks/use_dashbord_cookie';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  dashboardItem: {
    height: '100%',
  },
  add: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `${theme.palette.grey[400]} dotted`,
  },
  addButton: {
    color: theme.palette.grey[400],
  },
  addIcon: {
    fontSize: 50,
  },
}));

interface SortableItemProps {
  children: React.ReactNode;
}

const SortableItem = SortableElement(({ children }: SortableItemProps) => (
  <Grid item xs={12} sm={12} lg={6} xl={6}>
    {children}
  </Grid>
));

interface SortableListContainerProps {
  children: React.ReactNode;
}

const SortableListContainer = SortableContainer(
  ({ children }: SortableListContainerProps) => (
    <Grid container spacing={4}>
      {children}
    </Grid>
  )
);

const Dashboard = () => {
  const classes = useStyles();
  const [dialog, open, close] = useBooleanState(false);
  const { ids, add, remove, updateIds } = useDashboardCookie();

  const onAddClicked = () => {
    open();
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    updateIds(arrayMove(ids, oldIndex, newIndex).join(','));
  };

  return (
    <div className={classes.root}>
      <AddDashboardItemDialog open={dialog} onClose={close} onAdd={add} />
      <SortableListContainer
        axis="xy"
        onSortEnd={onSortEnd}
        useDragHandle={true}
      >
        {ids.map((value, index) => (
          <SortableItem key={value} index={index}>
            <DashboardItem
              className={classes.dashboardItem}
              id={Number(value)}
              onRemove={remove}
            />
          </SortableItem>
        ))}
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          <Paper
            variant="outlined"
            className={classes.dashboardItem + ' ' + classes.add}
          >
            <IconButton className={classes.addButton} onClick={onAddClicked}>
              <AddCircleOutline className={classes.addIcon} />
            </IconButton>
          </Paper>
        </Grid>
      </SortableListContainer>
    </div>
  );
};

export default Dashboard;
