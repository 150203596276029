import React, { useState, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Switch,
  FormControlLabel,
  IconButton,
  Button,
  Grid,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Axios from 'axios';
import { useSelector } from 'react-redux';
import store, { RootState } from 'stores';
import errorSlice from 'stores/slices/error';
import { ILinkGroup } from 'models/link_group';
import { ISite } from 'models/site';
import { Delete } from '@material-ui/icons';
import { TableRefContext } from 'components/pages/Links';
import theme from 'themes';
import { DialogProps } from 'models/dialog';

interface Props extends DialogProps {}

export interface RequestLinkChip {
  device_id: number;
  url: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({ formControl: { minWidth: '100%', maxWidth: '100%' } })
);

const AddLinkDialog = ({ open, onClose }: Props) => {
  const classes = useStyles();
  const error = useSelector((state: RootState) => state.error);
  const sites = useSelector((state: RootState) => state.site.data);
  const devices = useSelector((state: RootState) => state.device.data);
  const query = useSelector((state: RootState) => state.link_group.query);
  const [siteId, setSiteId] = useState(0);
  const [defaultLink, setDefaultLink] = useState('');
  const [removeReferrer, setRemoveReferrer] = useState(false);
  const [links, setLinks] = useState<RequestLinkChip[]>([]);
  const refContext = useContext(TableRefContext);

  const addLinkGroup = async () => {
    const _links = links.map((link) => {
      return {
        url: link.url,
        device_id: link.device_id !== 0 ? link.device_id : null,
      };
    });
    const response = await Axios.post<ILinkGroup[]>('/link', {
      site_id: siteId,
      remove_referrer: removeReferrer,
      default_link: defaultLink,
      links: _links,
    });
    if (response) {
      if (refContext.ref.current?.onQueryChange && query) {
        refContext.ref.current.onQueryChange(query);
      }

      reset();
      close();
    }
  };

  const addLinkClicked = () => {
    const _links = [...links];
    _links.push({ url: '', device_id: 1 });
    setLinks(_links);
  };

  const setLinkUrl = (index: number, value: string) => {
    const _links = [...links];
    _links[index].url = value;
    setLinks(_links);
  };

  const setLinkDeviceId = (index: number, value: number) => {
    const _links = [...links];
    _links[index].device_id = value;
    setLinks(_links);
  };

  const onDeleteLinkClicked = (index: number) => {
    const _links = [...links];
    _links.splice(index, 1);
    setLinks(_links);
  };

  const close = () => {
    store.dispatch(errorSlice.actions.clear());

    onClose();
  };

  const reset = () => {
    setSiteId(0);
    setRemoveReferrer(false);
    setDefaultLink('');
    setLinks([]);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">リンクグループ 追加</DialogTitle>

      <DialogContent>
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                value={removeReferrer}
                onChange={(e, checked) => {
                  setRemoveReferrer(checked);
                }}
              />
            }
            label="リファラカット"
          />
        </FormControl>

        <Autocomplete
          id="site_id"
          options={sites}
          getOptionLabel={(option) => option.url}
          onChange={(event: any, value: ISite | null) => {
            setSiteId(value?.id ?? 0);
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label="サイト"
                error={error.errors?.site_id ? true : false}
                helperText={error.errors?.site_id ?? ''}
              />
            );
          }}
        />

        <TextField
          value={defaultLink}
          error={error.errors?.default_link ? true : false}
          helperText={error.errors?.default_link ?? ''}
          onChange={(e) => setDefaultLink(e.target.value)}
          margin="normal"
          id="default_link"
          label="デフォルトリンク"
          type="default_link"
          fullWidth
        />

        {links.map((link, index) => {
          return (
            <Grid container spacing={2} key={index}>
              <Grid item xs={12} sm={7}>
                <TextField
                  value={link.url}
                  error={error.errors[`links.${index}.url`] ? true : false}
                  helperText={error.errors[`links.${index}.url`] ?? ''}
                  onChange={(e) => {
                    setLinkUrl(index, e.target.value);
                  }}
                  id="url"
                  label="URL"
                  type="string"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <FormControl
                  className={classes.formControl}
                  error={
                    error.errors[`links.${index}.device_id`] ? true : false
                  }
                >
                  <InputLabel>デバイス</InputLabel>
                  <Select
                    error={
                      error.errors[`links.${index}.device_id`] ? true : false
                    }
                    id="device_id"
                    value={link.device_id}
                    onChange={(e) => {
                      setLinkDeviceId(index, Number(e.target.value));
                      link.device_id = Number(e.target.value);
                    }}
                  >
                    {devices.map((device) => {
                      return (
                        <MenuItem key={device.id} value={device.id}>
                          {device.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>
                    {error.errors[`links.${index}.device_id`] ?? ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  style={{ marginTop: theme.spacing(2) }}
                  size="small"
                  aria-label="delete"
                  onClick={(e) => {
                    onDeleteLinkClicked(index);
                  }}
                >
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      </DialogContent>

      <DialogActions>
        <Button onClick={addLinkClicked}>リンク 追加</Button>
      </DialogActions>
      <DialogActions>
        <Button onClick={close} color="primary">
          キャンセル
        </Button>
        <Button onClick={addLinkGroup} color="primary">
          追加
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLinkDialog;
