import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'stores';
import Axios from 'axios';
import { User, IUser } from 'models/user';
import { setBearer } from 'helpers/axios';
import Cookies from 'js-cookie';
import { IToken } from 'models/token';

interface IInitialState {
  user: IUser;
  isAuthed: boolean;
}
const initialState: IInitialState = {
  user: { ...new User() },
  isAuthed: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      return { ...state, user: { ...action.payload } };
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      return { ...state, user: { ...state.user, email: action.payload } };
    },
    setIsAuthed: (state, action: PayloadAction<boolean>) => {
      return { ...state, isAuthed: action.payload };
    },
  },
});

export default slice;

export const checkAuth = (): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const accessToken = await dispatch(checkAccessToken());
    if (accessToken) {
      return true;
    }

    const refreshToken = await dispatch(checkRefreshToken());

    if (refreshToken) {
      return true;
    }

    throw new Error();
  } catch {
    dispatch(deauthorize());

    return false;
  }
};

export const checkAccessToken = (): AppThunk<Promise<boolean>> => async (
  dispatch
) => {
  try {
    const accessToken = Cookies.get('access_token');
    if (!accessToken) {
      throw new Error();
    }
    setBearer(accessToken);
    const response = await Axios.get<IUser>('/oauth/me');
    Cookies.set('access_token', accessToken);
    dispatch(slice.actions.setUser(response.data));
    dispatch(slice.actions.setIsAuthed(true));

    return true;
  } catch {
    return false;
  }
};

export const checkRefreshToken = (): AppThunk<Promise<boolean>> => async (
  dispatch
) => {
  try {
    const refreshToken = Cookies.get('refresh_token');
    if (!refreshToken) {
      throw new Error();
    }
    const response = await Axios.post<IToken>('/oauth/refresh', {
      refresh_token: refreshToken,
    });

    return await dispatch(setAuth(response.data));
  } catch {
    return false;
  }
};

export const authorize = (
  email: string,
  password: string
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const response = await Axios.post<IToken>('/oauth/token', {
      email,
      password,
    });

    await dispatch(setAuth(response.data));

    return true;
  } catch {
    return false;
  }
};

export const deauthorize = (): AppThunk<void> => async (dispatch) => {
  Axios.defaults.headers.Authorization = undefined;
  delete Axios.defaults.headers.Authorization;
  Cookies.remove('access_token');
  Cookies.remove('refresh_token');
  dispatch(slice.actions.setUser({ ...new User() }));
  dispatch(slice.actions.setIsAuthed(false));
};

export const setAuth = (token: IToken): AppThunk<Promise<boolean>> => async (
  dispatch
) => {
  try {
    setBearer(token.access_token);
    Cookies.set('access_token', token.access_token);
    Cookies.set('refresh_token', token.refresh_token);

    const response = await Axios.get<IUser>('/oauth/me');
    dispatch(slice.actions.setUser(response.data));
    dispatch(slice.actions.setIsAuthed(true));

    return true;
  } catch {
    return false;
  }
};

export const InvitationAuthentication = (
  token: string,
  password: string
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const response = await Axios.post<IUser>('/invitation/join', {
      token,
      password,
      password_confirmation: password,
    });

    const auth = await dispatch(authorize(response.data.email, password));
    if (!auth) {
      throw new Error();
    }

    return true;
  } catch {
    return false;
  }
};
