import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import AuthRoute from 'components/template/AuthRoute';
import Login from 'components/pages/Login';
import NotFound from 'components/pages/errors/NotFound';
import Minimal from 'components/template/Minimal';
import Main from 'components/template/Main';
import Dashboard from 'components/pages/Dashboard';
import Account from 'components/pages/Account';
import Users from 'components/pages/Users';
import Join from 'components/pages/Join';
import Sites from 'components/pages/Sites';
import Links from 'components/pages/Links';
import Devices from 'components/pages/Devices';
import DeviceGroups from 'components/pages/DeviceGroups';
import Logs from 'components/pages/Logs';
import ExcludedIp from 'components/pages/ExcludedIp';
import ResetPassword from 'components/pages/ResetPassword';
import ResetPasswordAuthenticate from 'components/pages/ResetPasswordAuthenticate';
import LogAggregate from 'components/pages/LogAggregate';
import { NonAuthRoutes, AuthRoutes } from 'models/route';
import { useAsync } from 'react-use';
import store from 'stores';
import { checkAuth } from 'stores/slices/auth';

const Router = () => {
  const auth = useAsync(async () => await store.dispatch(checkAuth()));

  if (auth.loading) {
    return <></>;
  }

  return (
    <BrowserRouter>
      <Switch>
        <AuthRoute
          exact
          path="/"
          requireAuth={true}
          redirectPath={NonAuthRoutes.login}
        >
          <Main>
            <Dashboard />
          </Main>
        </AuthRoute>

        <AuthRoute
          exact
          path="/logs"
          requireAuth={true}
          redirectPath={NonAuthRoutes.login}
        >
          <Main>
            <Logs />
          </Main>
        </AuthRoute>

        <AuthRoute
          exact
          path="/links"
          requireAuth={true}
          redirectPath={NonAuthRoutes.login}
        >
          <Main>
            <Links />
          </Main>
        </AuthRoute>

        <AuthRoute
          exact
          path="/links/:id"
          requireAuth={true}
          redirectPath={NonAuthRoutes.login}
        >
          <Main>
            <LogAggregate />
          </Main>
        </AuthRoute>

        <AuthRoute
          exact
          path="/sites"
          requireAuth={true}
          redirectPath={NonAuthRoutes.login}
        >
          <Main>
            <Sites />
          </Main>
        </AuthRoute>

        <AuthRoute
          exact
          path="/users"
          requireAuth={true}
          redirectPath={NonAuthRoutes.login}
        >
          <Main>
            <Users />
          </Main>
        </AuthRoute>

        <AuthRoute
          exact
          path="/devices"
          requireAuth={true}
          redirectPath={NonAuthRoutes.login}
        >
          <Main>
            <Devices />
          </Main>
        </AuthRoute>

        <AuthRoute
          exact
          path="/device-groups"
          requireAuth={true}
          redirectPath={NonAuthRoutes.login}
        >
          <Main>
            <DeviceGroups />
          </Main>
        </AuthRoute>

        <AuthRoute
          exact
          path="/account"
          requireAuth={true}
          redirectPath={NonAuthRoutes.login}
        >
          <Main>
            <Account />
          </Main>
        </AuthRoute>

        <AuthRoute
          exact
          path="/ip"
          requireAuth={true}
          redirectPath={NonAuthRoutes.login}
        >
          <Main>
            <ExcludedIp />
          </Main>
        </AuthRoute>

        <AuthRoute
          exact
          path="/login"
          requireAuth={false}
          redirectPath={AuthRoutes.index}
        >
          <Minimal>
            <Login />
          </Minimal>
        </AuthRoute>

        <AuthRoute
          exact
          path="/reset-password"
          requireAuth={false}
          redirectPath={AuthRoutes.index}
        >
          <Minimal>
            <ResetPassword />
          </Minimal>
        </AuthRoute>

        <AuthRoute
          exact
          path="/reset-password/authenticate"
          requireAuth={false}
          redirectPath={AuthRoutes.index}
        >
          <Minimal>
            <ResetPasswordAuthenticate />
          </Minimal>
        </AuthRoute>

        <AuthRoute
          exact
          path="/join"
          requireAuth={false}
          redirectPath={AuthRoutes.index}
        >
          <Minimal>
            <Join />
          </Minimal>
        </AuthRoute>

        <Route>
          <Minimal>
            <NotFound />
          </Minimal>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
