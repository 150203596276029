import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILinkGroup } from 'models/link_group';
import { AppThunk } from 'stores';
import Axios from 'axios';
import { Query } from 'material-table';

interface ILinkGroupStore {
  data: ILinkGroup[];
  query: Query<ILinkGroup> | null;
}

const initialState: ILinkGroupStore = {
  data: [],
  query: null,
};

const slice = createSlice({
  name: 'link',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<ILinkGroup[]>) => {
      return { ...state, data: action.payload };
    },
    setQuery: (state, action: PayloadAction<Query<ILinkGroup>>) => {
      return { ...state, query: action.payload };
    },
  },
});

export default slice;

export const getLinkGroups = (): AppThunk<Promise<boolean>> => async (
  dispatch
) => {
  try {
    const response = await Axios.get<ILinkGroup[]>('/link');
    dispatch(slice.actions.setData(response.data));

    return true;
  } catch {
    return false;
  }
};
