import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDevice } from 'models/device';
import { AppThunk } from 'stores';
import Axios from 'axios';

interface IDeviceStore {
  data: IDevice[];
}

const initialState: IDeviceStore = {
  data: [],
};

const slice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<IDevice[]>) => {
      return { ...state, data: action.payload };
    },
  },
});

export default slice;

export const getDevices = (): AppThunk<Promise<boolean>> => async (
  dispatch
) => {
  try {
    const response = await Axios.get<IDevice[]>('/device');
    dispatch(slice.actions.setData(response.data));

    return true;
  } catch {
    return false;
  }
};
