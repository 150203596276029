import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRole } from 'models/role';
import { AppThunk } from 'stores';
import Axios from 'axios';

interface IPermission {
  data: IRole[];
}

const initialState: IPermission = {
  data: [],
};

const slice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<IRole[]>) => {
      return { ...state, data: action.payload };
    },
  },
});

export default slice;

export const getPermissions = (): AppThunk<Promise<boolean>> => async (
  dispatch
) => {
  try {
    const response = await Axios.get<IRole[]>('/permission');
    dispatch(slice.actions.setData(response.data));

    return true;
  } catch {
    return false;
  }
};
