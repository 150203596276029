import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  CardActions,
  Button,
  makeStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import store, { RootState } from 'stores';
import useBooleanState from 'helpers/hooks/use_boolean_state';
import useRouter from 'helpers/hooks/use_rooter';
import { deauthorize } from 'stores/slices/auth';
import EditDetailDialog from './EditDetailDialog';

const useStyles = makeStyles((theme) => ({
  linkWrap: {
    margin: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  linkButton: {
    color: theme.palette.error.main,
  },
}));

const AccountDetails = () => {
  const classes = useStyles();
  const router = useRouter();
  const me = useSelector((state: RootState) => state.auth.user);
  const [dialog, open, close] = useBooleanState(false);

  const onExitClicked = () => {
    store.dispatch(deauthorize());
    router.push('/');
  };

  return (
    <>
      <EditDetailDialog open={dialog} onClose={close}></EditDetailDialog>

      <Card>
        <CardHeader title="プロフィール" />

        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                value={me.role?.title}
                inputProps={{ readOnly: true }}
                fullWidth
                label="ロール"
                name="role"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                value={me.email}
                inputProps={{ readOnly: true }}
                fullWidth
                label="メールアドレス"
                name="email"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardActions>
          <span style={{ flexGrow: 1 }}></span>
          <Button
            onClick={() => {
              open();
            }}
            variant="contained"
            color="primary"
          >
            編集
          </Button>
        </CardActions>
      </Card>

      <div className={classes.linkWrap}>
        <Button
          className={classes.linkButton}
          onClick={() => {
            onExitClicked();
          }}
        >
          ログアウト
        </Button>
      </div>
    </>
  );
};

export default AccountDetails;
