import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  Button,
} from '@material-ui/core';

import Axios from 'axios';
import { useSelector } from 'react-redux';
import store, { RootState } from 'stores';
import errorSlice from 'stores/slices/error';
import { IDeviceGroup } from 'models/device_group';
import deviceGroupSlice from 'stores/slices/device_group';
import { DialogProps } from 'models/dialog';

interface Props extends DialogProps {}

const AddDeviceGroupDialog = ({ open, onClose }: Props) => {
  const error = useSelector((state: RootState) => state.error);
  const [name, setName] = useState('');

  const addDeviceGroup = async () => {
    const response = await Axios.post<IDeviceGroup[]>('/device/group', {
      name,
    });
    if (response) {
      store.dispatch(deviceGroupSlice.actions.setData(response.data));

      reset();
      close();
    }
  };

  const close = () => {
    store.dispatch(errorSlice.actions.clear());

    onClose();
  };

  const reset = () => {
    setName('');
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">デバイスグループ 追加</DialogTitle>

      <DialogContent>
        <TextField
          value={name}
          error={error.errors?.name ? true : false}
          helperText={error.errors?.name ?? ''}
          onChange={(e) => setName(e.target.value)}
          autoFocus
          margin="dense"
          id="name"
          label="名前"
          type="name"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          キャンセル
        </Button>
        <Button onClick={addDeviceGroup} color="primary">
          追加
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDeviceGroupDialog;
