import React from 'react';
import {
  TableContainer,
  TableBody,
  Table,
  TableRow,
  TableCell,
  withStyles,
  createStyles,
  Theme,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import { RootState } from 'stores';
import { useSelector } from 'react-redux';

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  })
)(TableRow);

const AccountAbilitiesTable = () => {
  const abilities = useSelector(
    (state: RootState) => state.auth.user.role?.abilities
  );

  return (
    <Card>
      <CardHeader title="権限" />
      <CardContent style={{ padding: 0 }}>
        <TableContainer>
          <Table>
            <TableBody>
              {abilities?.map((ability) => (
                <StyledTableRow key={ability.id}>
                  <TableCell component="th">{ability.name}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default AccountAbilitiesTable;
