import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import LinkToolbar from 'components/organisms/pages/Links/LinksToolbar';
import LinksTable from 'components/organisms/pages/Links/LinksTable';
import store from 'stores';
import { check } from 'helpers/rbac';
import { Redirect } from 'react-router-dom';
import { AuthRoutes } from 'models/route';
import { getSites } from 'stores/slices/site';
import { getDevices } from 'stores/slices/device';
import { MaterialTableProps } from 'material-table';
import { ILinkGroup } from 'models/link_group';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

export const TableRefContext = React.createContext({
  ref: React.createRef<MaterialTableProps<ILinkGroup> | undefined>(),
  setRef: (
    ref: React.MutableRefObject<MaterialTableProps<ILinkGroup> | undefined>
  ) => {},
});

const Links = () => {
  const canManageLinks = check('manage-links');
  const canShowConfig = check('show-config');
  const classes = useStyles();
  const [tableRef, setTableRef] = useState<
    React.MutableRefObject<MaterialTableProps<ILinkGroup> | undefined>
  >(useRef());

  if (!canManageLinks) {
    return (
      <Redirect
        to={{
          pathname: AuthRoutes.index,
        }}
      />
    );
  }

  // created
  store.dispatch(getSites());
  if (canShowConfig) {
    store.dispatch(getDevices());
  }

  return (
    <TableRefContext.Provider value={{ ref: tableRef, setRef: setTableRef }}>
      <div className={classes.root}>
        <LinkToolbar />
        <div className={classes.content}>
          <LinksTable />
        </div>
      </div>
    </TableRefContext.Provider>
  );
};

export default Links;
