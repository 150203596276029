import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import ApexChart from 'apexcharts';
import { useSelector } from 'react-redux';
import { RootState } from 'stores';
import dayjs from 'dayjs';

const LogAggregateChart = () => {
  const deviceGroups = useSelector(
    (state: RootState) => state.device_group.data
  );
  const _deviceGroups = [{ id: 0, name: 'PC' }, ...deviceGroups];
  const [dates, setDates] = useState<dayjs.Dayjs[]>([]);
  const logAggregate = useSelector(
    (state: RootState) => state.log_aggregate.data
  );

  useEffect(() => {
    if (!logAggregate.from || !logAggregate.to) {
      return;
    }

    // 表示する日付の配列作成
    const dates = [];
    const from = dayjs(logAggregate.from);
    const to = dayjs(logAggregate.to);
    for (let i = from; i.isBefore(to.add(1, 'day')); i = i.add(1, 'day')) {
      dates.push(i);
    }
    setDates(dates);
  }, [logAggregate]);

  const options: ApexChart.ApexOptions = {
    xaxis: {
      categories: dates.map((i) => i.format('YYYY-MM-DD ddd')),
    },
    plotOptions: {
      bar: {
        columnWidth: '10%',
      },
    },
  };
  const series: ApexChart.ApexOptions['series'] = [
    {
      name: 'Total',
      type: 'line',
      data: dates.map((date) => {
        const total = logAggregate.logs[date.format('YYYY-MM-DD')]?.total;
        return total ?? 0;
      }),
    },
    ..._deviceGroups.map((deviceGroup) => {
      let data: number[] = [];
      // eslint-disable-next-line array-callback-return
      dates.map((date) => {
        const findedDeviceGroup = logAggregate.logs[
          date.format('YYYY-MM-DD')
        ]?.device_groups.find((group) => group.name === deviceGroup.name);
        if (findedDeviceGroup) {
          data.push(findedDeviceGroup.total);
        } else {
          data.push(0);
        }
      });

      return {
        name: deviceGroup.name,
        type: 'column',
        data,
      };
    }),
  ];

  return <Chart height="100%" options={options} series={series} type="line" />;
};

export default LogAggregateChart;
