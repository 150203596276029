import React from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from 'themes';
import 'typeface-roboto';
import Router from 'Router';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import store from 'stores';
import errorSlice, { IError } from 'stores/slices/error';
import notificationSlice from 'stores/slices/notification';
import Notification from 'components/organisms/Notification';

const onSuccess = (response: AxiosResponse) => {
  store.dispatch(errorSlice.actions.clear());

  return response;
};

// エラーの場合はストアにエラーをセットする
const onError = (err: AxiosError<IError>) => {
  if (!err.response) {
    return;
  }
  if (!err.response.data.message || !err.response.data.errors) {
    return;
  }
  // JSON にシリアライズできない値を
  // アクションのペイロード（引数）に含めるとエラーになるため、
  // 必要なキーのみ取り出してアクションに渡す
  const { data } = err.response;
  // コンポーネントの外でアクションを呼び出したい場合は、
  // store の dispatch メソッドを利用する
  store.dispatch(errorSlice.actions.set(data));
  store.dispatch(
    notificationSlice.actions.set({
      content: data.message,
      severity: 'error',
    })
  );
  // エラーをスローしたい場合は...
  // Promise.reject(err);
};

Axios.interceptors.response.use(onSuccess, onError);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Router />

      <Notification />
    </ThemeProvider>
  );
};

export default App;
