import { IRole } from './role';

export interface IUser {
  id: number;
  role_id: number;
  email: string;
  created_at: string | null;
  updated_at: string | null;
  role?: IRole;
}

export class User implements IUser {
  public id = 0;
  public role_id = 0;
  public email = '';
  public created_at = null;
  public updated_at = null;
}
