import React from 'react';
import { makeStyles } from '@material-ui/core';
import DevicesToolbar from 'components/organisms/pages/Devices/DevicesToolbar';
import DevicesTable from 'components/organisms/pages/Devices/DevicesTable';
import store from 'stores';
import { getDevices } from 'stores/slices/device';
import { getDeviceGroups } from 'stores/slices/device_group';
import { check } from 'helpers/rbac';
import { Redirect } from 'react-router-dom';
import { AuthRoutes } from 'models/route';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const Devices = () => {
  const classes = useStyles();

  if (!check('manage-config')) {
    return (
      <Redirect
        to={{
          pathname: AuthRoutes.index,
        }}
      />
    );
  }

  // created
  store.dispatch(getDevices());
  store.dispatch(getDeviceGroups());

  return (
    <div className={classes.root}>
      <DevicesToolbar />
      <div className={classes.content}>
        <DevicesTable />
      </div>
    </div>
  );
};

export default Devices;
