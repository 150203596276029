import React from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import store, { RootState } from 'stores';
import { Delete } from '@material-ui/icons';
import Axios from 'axios';
import { IIp } from 'models/ip';
import excludedIpSlice from 'stores/slices/excluded_ip';
import { check } from 'helpers/rbac';

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  iconButtonLeft: {
    marginRight: theme.spacing(1),
  },
}));

const CustomizedTables = () => {
  const classes = useStyles();
  const deviceGroups = useSelector(
    (state: RootState) => state.excluded_ip.data
  );
  const canManageExcludedIps = check('manage-excluded-ips');
  const onDeleteClicked = async (id: number) => {
    const isConfirm = window.confirm('削除');
    if (!isConfirm) {
      return;
    }

    const response = await Axios.delete<IIp[]>(`/ip/${id}`);
    if (response) {
      store.dispatch(excludedIpSlice.actions.setData(response.data));
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell>IP</TableCell>
              {canManageExcludedIps ? (
                <TableCell align="right">アクション</TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {deviceGroups.map((deviceGroup) => (
              <StyledTableRow key={deviceGroup.id}>
                <TableCell component="th" scope="row">
                  {deviceGroup.ip}
                </TableCell>
                {canManageExcludedIps ? (
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      aria-label="delete"
                      onClick={() => {
                        onDeleteClicked(deviceGroup.id);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                ) : null}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomizedTables;
