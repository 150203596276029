import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  FormControl,
  InputLabel,
  Select,
  makeStyles,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';

import Axios from 'axios';
import { useSelector } from 'react-redux';
import store, { RootState } from 'stores';
import errorSlice from 'stores/slices/error';
import { IUser } from 'models/user';
import { getUsers } from 'stores/slices/user';
import { DialogProps } from 'models/dialog';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
}));

interface Props extends DialogProps {
  id: number;
  role_id: number;
}

const EditUserDialog = ({ open, id, role_id, onClose }: Props) => {
  const classes = useStyles();
  const permissions = useSelector((state: RootState) => state.permission.data);
  const error = useSelector((state: RootState) => state.error);
  const [editRoleId, setEditRoleId] = useState(0);

  const editUser = async () => {
    const response = await Axios.put<IUser>(`/user/${id}`, {
      role_id: editRoleId,
    });
    if (response) {
      store.dispatch(getUsers());
      close();
    }
  };

  const close = () => {
    store.dispatch(errorSlice.actions.clear());

    onClose();
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    setEditRoleId(role_id);
  }, [open, role_id]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">ユーザー 編集</DialogTitle>

      <DialogContent>
        <FormControl
          className={classes.formControl}
          error={error.errors?.role_id ? true : false}
        >
          <InputLabel>ロール</InputLabel>
          <Select
            error={error.errors?.role_id ? true : false}
            id="role"
            value={editRoleId}
            onChange={(e) => {
              const value = e.target.value as number;
              setEditRoleId(value);
            }}
          >
            {permissions.map((permission) => (
              <MenuItem key={permission.id} value={permission.id}>
                {permission.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{error.errors?.role_id ?? ''}</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          キャンセル
        </Button>
        <Button onClick={editUser} color="primary">
          編集
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserDialog;
