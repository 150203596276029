import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import LogAggregateChart from 'components/organisms/pages/LogAggregate/LogAggregateChart';
import store from 'stores';
import { getDeviceGroups } from 'stores/slices/device_group';
import LogAggregateToolbar from 'components/organisms/pages/LogAggregate/LogAggregateToolbar';
import logAggregateSlice, {
  getLogAggregate,
} from 'stores/slices/log_aggregate';
import referrerAggregateSlice from 'stores/slices/referrer_aggregate';
import { useParams } from 'react-router-dom';
import LogAggregateTable from 'components/organisms/pages/LogAggregate/LogAggregateTable';
import LogAggregateInformation from 'components/organisms/pages/LogAggregate/LogAggregateInformation';
import ReferrerAggregate from 'components/organisms/pages/LogAggregate/ReferrerAggregate';
import { useUnmount } from 'react-use';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  chartWrapper: {
    height: '300px',
    padding: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(2),
  },
}));

const LogAggregate = () => {
  const { id } = useParams();
  const classes = useStyles();

  // created
  store.dispatch(getDeviceGroups());
  store.dispatch(getLogAggregate(Number(id)));

  useUnmount(() => {
    store.dispatch(logAggregateSlice.actions.clearData());
    store.dispatch(referrerAggregateSlice.actions.clearData());
  });

  return (
    <div className={classes.root}>
      <LogAggregateToolbar />

      <div className={classes.content}>
        <LogAggregateInformation id={id} />
        <ReferrerAggregate id={id} className={classes.table} />
        <Paper className={`${classes.chartWrapper} ${classes.table}`}>
          <LogAggregateChart />
        </Paper>

        <LogAggregateTable className={classes.table} />
      </div>
    </div>
  );
};

export default LogAggregate;
