import React, { useState } from 'react';
import store from 'stores';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@material-ui/core';
import { useEffectOnce, useCopyToClipboard } from 'react-use';
import { ILinkGroup } from 'models/link_group';
import Axios from 'axios';
import { Check, FileCopy } from '@material-ui/icons';

import notificationSlice from 'stores/slices/notification';

interface props {
  className?: string;
  id: string | undefined;
}

const LogAggregateTable = ({ className, id }: props) => {
  const [linkGroup, setLinkGroup] = useState<ILinkGroup>({
    id: 0,
    user_id: 0,
    site_id: 0,
    remove_referrer: false,
    copy_link: '',
    code: '',
    links: [],
  });
  const [, copyToClipboard] = useCopyToClipboard();

  const onCopyClicked = (link: string) => {
    copyToClipboard(link);

    store.dispatch(
      notificationSlice.actions.set({
        content: 'リンクをクリップボードにコピーしました',
        severity: 'success',
      })
    );
  };

  useEffectOnce(() => {
    try {
      (async () => {
        const response = await Axios.get<ILinkGroup>(`/link/${id}`);
        setLinkGroup(response.data);
      })();
    } catch {}
  });

  return (
    <div className={className}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>URL</TableCell>
              <TableCell>コード</TableCell>
              <TableCell>リダイレクトURL</TableCell>
              <TableCell align="center">リファラカット</TableCell>
              <TableCell align="center">アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {linkGroup?.site?.url}
              </TableCell>
              <TableCell>{linkGroup?.code}</TableCell>
              <TableCell>
                {
                  linkGroup?.links?.find((link) => link.device_id === null)?.url
                    .url
                }
              </TableCell>
              <TableCell align="center">
                {linkGroup?.remove_referrer ? <Check /> : null}
              </TableCell>
              <TableCell align="center">
                <IconButton
                  size="small"
                  aria-label="copy"
                  onClick={() => {
                    onCopyClicked(linkGroup.copy_link ?? '');
                  }}
                >
                  <FileCopy />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LogAggregateTable;
