import React, { useState } from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import store, { RootState } from 'stores';
import EditDeviceGroupDialog from 'components/organisms/pages/DeviceGroups/EditDeviceGroupDialog';
import Axios from 'axios';
import { IDeviceGroup } from 'models/device_group';
import deviceGroupSlice from 'stores/slices/device_group';
import useBooleanState from 'helpers/hooks/use_boolean_state';

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  iconButtonLeft: {
    marginRight: theme.spacing(1),
  },
}));

const CustomizedTables = () => {
  const classes = useStyles();
  const deviceGroups = useSelector(
    (state: RootState) => state.device_group.data
  );
  const [dialog, open, close] = useBooleanState(false);
  const [id, setId] = useState(0);
  const [name, setName] = useState('');

  const onEditClicked = (id: number, name: string) => {
    setId(id);
    setName(name);
    open();
  };

  const onDeleteClicked = async (id: number) => {
    const isConfirm = window.confirm('削除');
    if (!isConfirm) {
      return;
    }

    const response = await Axios.delete<IDeviceGroup[]>(`/device/group/${id}`);
    if (response) {
      store.dispatch(deviceGroupSlice.actions.setData(response.data));
    }
  };

  return (
    <>
      <EditDeviceGroupDialog
        open={dialog}
        onClose={close}
        id={id}
        name={name}
      />

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell>名前</TableCell>
              <TableCell align="right">アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deviceGroups.map((deviceGroup) => (
              <StyledTableRow key={deviceGroup.id}>
                <TableCell component="th" scope="row">
                  {deviceGroup.name}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    className={classes.iconButtonLeft}
                    size="small"
                    aria-label="edit"
                    onClick={() => {
                      onEditClicked(deviceGroup.id, deviceGroup.name);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    size="small"
                    aria-label="delete"
                    onClick={() => {
                      onDeleteClicked(deviceGroup.id);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomizedTables;
