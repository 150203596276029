import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    fontFamily: `'Nunito', sans-serif`,
    paddingBottom: 64,
  },
  code: {
    fontSize: '26px',
    padding: '0 15px',
    borderRight: '2px solid',
  },
  message: {
    fontSize: '18px',
    padding: '10px',
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <Box
      className={classes.content}
      alignItems="center"
      justifyContent="center"
      display="flex"
      height="100%"
      width="100%"
    >
      <div className={classes.code}>404</div>
      <div className={classes.message}>Not Found</div>
    </Box>
  );
};

export default NotFound;
