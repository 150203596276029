import React, { useState } from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { Delete, Edit } from '@material-ui/icons';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import store, { RootState } from 'stores';
import { IconButton } from '@material-ui/core';
import useBooleanState from 'helpers/hooks/use_boolean_state';
import { IUser } from 'models/user';
import userSlice from 'stores/slices/user';
import Axios from 'axios';
import EditUserDialog from './EditUserDialog';

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  iconButtonLeft: {
    marginRight: theme.spacing(1),
  },
}));

const CustomizedTables = () => {
  const classes = useStyles();
  const [dialog, open, close] = useBooleanState(false);
  const users = useSelector((state: RootState) => state.user.data);
  const authedUser = useSelector((state: RootState) => state.auth.user);
  const [id, setId] = useState(0);
  const [roleId, setRoleId] = useState(0);

  const onEditClicked = (id: number, roleId: number) => {
    setId(id);
    setRoleId(roleId);
    open();
  };

  const onDeleteClicked = async (id: number) => {
    const isConfirm = window.confirm('削除');
    if (!isConfirm) {
      return;
    }

    const response = await Axios.delete<IUser[]>(`/user/${id}`);
    if (response) {
      store.dispatch(userSlice.actions.setData(response.data));
    }
  };

  return (
    <>
      <EditUserDialog open={dialog} onClose={close} id={id} role_id={roleId} />

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell>メールアドレス</TableCell>
              <TableCell>ロール</TableCell>
              <TableCell align="right">アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <StyledTableRow key={user.id}>
                <TableCell component="th" scope="row">
                  {user.email}
                </TableCell>
                <TableCell>{user.role?.name}</TableCell>
                <TableCell align="right">
                  <IconButton
                    className={classes.iconButtonLeft}
                    size="small"
                    disabled={user.id === authedUser.id}
                    aria-label="edit"
                    onClick={() => {
                      onEditClicked(user.id, user.role_id);
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    size="small"
                    disabled={user.id === authedUser.id}
                    aria-label="delete"
                    onClick={() => {
                      onDeleteClicked(user.id);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomizedTables;
