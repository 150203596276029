import React, { useEffect, useRef, useContext } from 'react';
import store, { RootState } from 'stores';
import Axios from 'axios';
import { ILog } from 'models/log';
import logSlice from 'stores/slices/log';
import MaterialTable, {
  Column,
  Options,
  Query,
  QueryResult,
  MaterialTableProps,
} from 'material-table';
import tableIcons from 'components/molecules/MaterialTableIcons';
import theme from 'themes';
import { IPagination } from 'models/pagination';
import { convertQuery } from 'helpers/pagination';
import { TableRefContext } from 'components/pages/Logs';
import { useSelector } from 'react-redux';
import { usePromise } from 'react-use';

const LogsTable = () => {
  const mounted = usePromise();
  const devices = useSelector((state: RootState) => state.device.data);
  const tableRef = useRef<MaterialTableProps<ILog>>();
  const refContext = useContext(TableRefContext);

  const columns: Column<ILog>[] = [
    {
      title: 'URL',
      field: 'link.link_group.site.url',
    },
    {
      title: 'コード',
      field: 'link.link_group.code',
    },
    {
      title: 'IP',
      field: 'ip',
    },
    {
      title: 'デバイス',
      field: 'device_id',
      render: (rowData) =>
        devices.find((device) => device.id === rowData.device_id)?.name,
    },
    {
      title: 'リファラー',
      field: 'referrer',
    },
    {
      title: '作成日',
      field: 'created_at',
    },
  ];

  const options: Options = {
    showTitle: false,
    toolbar: false,
    pageSize: 10,
    rowStyle: (data, index) => {
      if (index % 2 === 0) {
        return { backgroundColor: theme.palette.background.default };
      }
      return {};
    },
    draggable: false,
    sorting: false,
  };

  const fetchData = async (query: Query<ILog>): Promise<QueryResult<ILog>> => {
    try {
      store.dispatch(logSlice.actions.setQuery({ ...query }));
      const convertedQuery = convertQuery(query);
      const response = await mounted(
        Axios.get<IPagination<ILog>>('./log', {
          params: convertedQuery,
        })
      );

      return {
        data: response.data.data,
        page: response.data.current_page - 1,
        totalCount: response.data.total,
      };
    } catch {
      return { data: [], page: 0, totalCount: 0 };
    }
  };

  useEffect(() => {
    if (tableRef.current) {
      refContext.setRef(tableRef);
    }
  }, [refContext]);

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        data={fetchData}
        options={options}
        tableRef={tableRef}
      />
    </>
  );
};

export default LogsTable;
