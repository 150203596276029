import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core';

import Header from 'components/molecules/Header';
import Sidebar from 'components/molecules/Sidebar';
import useBooleanState from 'helpers/hooks/use_boolean_state';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: '100vh',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    height: '100%',
  },
}));

interface Props {
  children: React.ReactNode;
}

const Main = ({ children }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const [sidebar, open, close] = useBooleanState(false);

  const shouldOpenSidebar = isDesktop ? true : sidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Header onSidebarOpen={open} />
      <Sidebar
        onClose={close}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default Main;
