import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import store, { RootState } from 'stores';
import { useSelector } from 'react-redux';
import notificationSlice from 'stores/slices/notification';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Notification = () => {
  const notification = useSelector((state: RootState) => state.notification);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    store.dispatch(notificationSlice.actions.hide());
  };

  return (
    <Snackbar
      open={notification.open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={notification.severity}>
        {notification.content}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
