import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  makeStyles,
  Button,
} from '@material-ui/core';

import Axios from 'axios';
import { useSelector } from 'react-redux';
import store, { RootState } from 'stores';
import errorSlice from 'stores/slices/error';
import { IDevice } from 'models/device';
import deviceSlice from 'stores/slices/device';
import { DialogProps } from 'models/dialog';

interface Props extends DialogProps {}

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
}));

const AddDeviceDialog = ({ open, onClose }: Props) => {
  const classes = useStyles();
  const error = useSelector((state: RootState) => state.error);
  const deviceGroups = useSelector(
    (state: RootState) => state.device_group.data
  );
  const [name, setName] = useState('');
  const [regex, setRegex] = useState('');
  const [deviceGroupId, setDeviceGroupId] = useState(0);

  const addDevice = async () => {
    let device_group_id: number | null = deviceGroupId;
    if (device_group_id === 0) {
      device_group_id = null;
    }
    const response = await Axios.post<IDevice[]>('/device', {
      device_group_id,
      name,
      regex,
    });
    if (response) {
      store.dispatch(deviceSlice.actions.setData(response.data));

      reset();
      close();
    }
  };

  const close = () => {
    store.dispatch(errorSlice.actions.clear());

    onClose();
  };

  const reset = () => {
    setName('');
    setRegex('');
    setDeviceGroupId(0);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">デバイス 追加</DialogTitle>

      <DialogContent>
        <TextField
          value={name}
          error={error.errors?.name ? true : false}
          helperText={error.errors?.name ?? ''}
          onChange={(e) => setName(e.target.value)}
          autoFocus
          margin="dense"
          id="name"
          label="名前"
          type="name"
          fullWidth
        />
        <TextField
          value={regex}
          error={error.errors?.regex ? true : false}
          helperText={error.errors?.regex ?? ''}
          onChange={(e) => setRegex(e.target.value)}
          margin="dense"
          id="regex"
          label="正規表現"
          type="regex"
          fullWidth
        />
        <FormControl
          className={classes.formControl}
          error={error.errors?.device_group_id ? true : false}
        >
          <InputLabel>デバイスグループ</InputLabel>
          <Select
            error={error.errors?.device_group_id ? true : false}
            id="device_group"
            value={deviceGroupId}
            onChange={(e) => {
              const value = e.target.value as number;
              setDeviceGroupId(value);
            }}
          >
            <MenuItem key={0} value={0}>
              none
            </MenuItem>
            {deviceGroups.map((deviceGroup) => {
              return (
                <MenuItem key={deviceGroup.id} value={deviceGroup.id}>
                  {deviceGroup.name}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{error.errors?.device_group_id ?? ''}</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          キャンセル
        </Button>
        <Button onClick={addDevice} color="primary">
          追加
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDeviceDialog;
