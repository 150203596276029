import React, { FormEvent, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useSelector } from 'react-redux';
import { RootState } from 'stores';
import { Typography, Link, Dialog, DialogTitle } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import useBooleanState from 'helpers/hooks/use_boolean_state';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginTop: theme.spacing(3),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const error = useSelector((state: RootState) => state.error);
  const [dialog, open] = useBooleanState(false);
  const [email, setEmail] = useState('');

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const response = await Axios.post('/user/reset-password', {
      email,
    });
    if (response) {
      open();
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />

      <Dialog open={dialog}>
        <DialogTitle>{email}にメールを送信しました</DialogTitle>
      </Dialog>

      <div className={classes.paper}>
        <Typography className={classes.title} variant="h2">
          パスワードリセット
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={error.errors?.email ? true : false}
            helperText={error.errors?.email ?? ''}
            margin="normal"
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            メールを送信する
          </Button>
          <Typography color="textSecondary" variant="body2">
            <Link component={NavLink} to="/login" variant="body1">
              ログイン
            </Link>
          </Typography>
        </form>
      </div>
    </Container>
  );
};

export default ResetPassword;
