import React, { forwardRef } from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  List,
  ListItem,
  Button,
  colors,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Settings, ExpandLess, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: colors.blueGrey[600],
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
  nentedList: {
    marginLeft: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const CustomRouterLink = forwardRef((props: NavLinkProps, ref: any) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <NavLink {...props} />
  </div>
));

interface LinkListItem {
  hide?: boolean;
  title: string;
  href: string;
  icon: JSX.Element;
}

interface Props {
  className: string;
  pages: LinkListItem[];
  settings: LinkListItem[];
}

const SidebarNav = ({ className, pages, settings }: Props) => {
  const [open, setOpen] = React.useState(true);

  const classes = useStyles();

  return (
    <List className={clsx(classes.root, className)}>
      {pages.map((page) => {
        if (page.hide) {
          // eslint-disable-next-line array-callback-return
          return;
        }
        return (
          <ListItem className={classes.item} disableGutters key={page.title}>
            <Button
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              exact={page.href === '/'}
              to={page.href}
            >
              <div className={classes.icon}>{page.icon}</div>
              {page.title}
            </Button>
          </ListItem>
        );
      })}

      <ListItem className={classes.item} disableGutters>
        <Button
          className={classes.button}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div className={classes.icon}>
            <Settings />
          </div>
          設定
          <ListItemText />
          {open ? <ExpandLess /> : <ExpandMore />}
        </Button>
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.nentedList}>
          {settings.map((page) => {
            if (page.hide) {
              // eslint-disable-next-line array-callback-return
              return;
            }
            return (
              <ListItem
                className={classes.item}
                disableGutters
                key={page.title}
              >
                <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  component={CustomRouterLink}
                  exact={page.href === '/'}
                  to={page.href}
                >
                  <div className={classes.icon}>{page.icon}</div>
                  {page.title}
                </Button>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
};

export default SidebarNav;
