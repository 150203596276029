import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import logo from 'assets/img/logo.png';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
  },
  logo: {
    width: 40,
    display: 'block',
  },
}));

interface Props {
  className?: string;
}

const MinimalHeader = ({ className }: Props) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="secondary"
      position="fixed"
    >
      <Toolbar>
        <RouterLink to="/">
          <img className={classes.logo} alt="Logo" src={logo} />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default MinimalHeader;
