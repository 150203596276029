import { ILogAggregate } from 'models/log_aggregate';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'stores';
import Axios from 'axios';

interface ILogAggregateState {
  data: ILogAggregate;
}
const initialState: ILogAggregateState = {
  data: {
    from: null,
    to: null,
    logs: {},
  },
};

const slice = createSlice({
  name: 'log_aggregate',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<ILogAggregate>) => {
      return { ...state, data: action.payload };
    },
    clearData: (state) => {
      return initialState;
    },
  },
});

export default slice;

export const getLogAggregate = (
  id: number,
  params: { from?: string; to?: string } = {}
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const response = await Axios.get<ILogAggregate>(`/log/${id}`, {
      params,
    });
    dispatch(slice.actions.setData(response.data));

    return true;
  } catch {
    return false;
  }
};
