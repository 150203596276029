import { IReferrerAggregate } from 'models/referrer_aggregate';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'stores';
import Axios from 'axios';

interface IReferrerAggregateState {
  data: IReferrerAggregate;
}
const initialState: IReferrerAggregateState = {
  data: {
    from: null,
    to: null,
    referrer: [],
  },
};

const slice = createSlice({
  name: 'referrer_aggregate',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<IReferrerAggregate>) => {
      return { ...state, data: action.payload };
    },
    clearData: (state) => {
      return initialState;
    },
  },
});

export default slice;

export const getReferrerAggregate = (
  id: number,
  params: { from?: string; to?: string } = {}
): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const response = await Axios.get<IReferrerAggregate>(
      `/log/referrer/${id}`,
      {
        params,
      }
    );
    dispatch(slice.actions.setData(response.data));

    return true;
  } catch {
    return false;
  }
};
