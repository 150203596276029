import React from 'react';
import clsx from 'clsx';
import { makeStyles, Drawer } from '@material-ui/core';
import {
  Dashboard,
  People,
  Devices,
  PhoneIphone,
  AccountBox,
  Web,
  Public,
  Link,
  ShowChart,
} from '@material-ui/icons';
import { check } from 'helpers/rbac';
import SidebarNav from './SidebarNav';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  className?: string;
  open: boolean;
  onClose: () => void;
  variant: 'permanent' | 'persistent' | 'temporary' | undefined;
}

const Sidebar = ({ className, open, onClose, variant }: Props) => {
  const classes = useStyles();

  const pages = [
    {
      title: 'ダッシュボード',
      href: '/',
      icon: <Dashboard />,
    },
    {
      title: 'ログ',
      href: '/logs',
      icon: <ShowChart />,
    },
    {
      title: 'リンク',
      href: '/links',
      icon: <Link />,
    },
    {
      title: 'サイト',
      href: '/sites',
      icon: <Web />,
    },
  ];

  const settings = [
    {
      title: 'アカウント',
      href: '/account',
      icon: <AccountBox />,
    },
    {
      title: 'ユーザー',
      href: '/users',
      icon: <People />,
    },
    {
      hide: !check('show-excluded-ips'),
      title: '除外IP',
      href: '/ip',
      icon: <Public />,
    },
    {
      hide: !check('manage-config'),
      title: 'デバイス',
      href: '/devices',
      icon: <PhoneIphone />,
    },
    {
      hide: !check('manage-config'),
      title: 'デバイスグループ',
      href: '/device-groups',
      icon: <Devices />,
    },
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div className={clsx(classes.root, className)}>
        <SidebarNav className={classes.nav} pages={pages} settings={settings} />
      </div>
    </Drawer>
  );
};

export default Sidebar;
