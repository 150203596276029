import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'stores';
import Axios from 'axios';
import App from './App';

const baseUrl = process.env.REACT_APP_BASE_URL || window.location.origin;
const apiPath = (process.env.REACT_APP_API_PATH || '').replace(/\/$/, '');
Axios.defaults.baseURL = `${baseUrl}/${apiPath}`;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
