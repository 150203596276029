import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IErrorContent {
  [key: string]: string[];
}

export interface IError {
  message: string;
  errors: IErrorContent;
}

const initialState: IError = {
  message: '',
  errors: {},
};

const slice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<IError>) => {
      return {
        ...state,
        message: action.payload.message,
        errors: action.payload.errors,
      };
    },
    clear: (state) => {
      return { ...state, ...initialState };
    },
  },
});

export default slice;
