import React, { useState, useEffect, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  Button,
} from '@material-ui/core';

import Axios from 'axios';
import { useSelector } from 'react-redux';
import store, { RootState } from 'stores';
import errorSlice from 'stores/slices/error';
import { ISite } from 'models/site';
import { TableRefContext } from 'components/pages/Sites';
import { DialogProps } from 'models/dialog';

interface Props extends DialogProps {
  id: number;
  url: string;
}

const EditSiteDialog = ({ id, url, open, onClose }: Props) => {
  const error = useSelector((state: RootState) => state.error);
  const query = useSelector((state: RootState) => state.site.query);
  const [editUrl, setEditUrl] = useState('');
  const refContext = useContext(TableRefContext);

  const editSite = async () => {
    const response = await Axios.put<ISite[]>(`/site/${id}`, {
      url: editUrl,
    });
    if (response) {
      if (refContext.ref.current?.onQueryChange && query) {
        refContext.ref.current.onQueryChange(query);
      }
      close();
    }
  };

  const close = () => {
    store.dispatch(errorSlice.actions.clear());

    onClose();
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    setEditUrl(url);
  }, [open, url]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">サイト 編集</DialogTitle>

      <DialogContent>
        <TextField
          error={error.errors?.url ? true : false}
          helperText={error.errors?.url ?? ''}
          onChange={(e) => setEditUrl(e.target.value)}
          autoFocus
          value={editUrl}
          margin="dense"
          id="url"
          label="URL"
          type="url"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          キャンセル
        </Button>
        <Button onClick={editSite} color="primary">
          編集
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSiteDialog;
