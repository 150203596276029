import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  makeStyles,
  Button,
} from '@material-ui/core';
import Axios from 'axios';
import { useSelector } from 'react-redux';
import store, { RootState } from 'stores';
import errorSlice from 'stores/slices/error';
import { IDevice } from 'models/device';
import deviceSlice from 'stores/slices/device';
import { DialogProps } from 'models/dialog';

interface Props extends DialogProps {
  id: number;
  name: string;
  regex: string;
  device_group_id: number;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
}));

const EditDeviceToolbar = ({
  open,
  id,
  name,
  regex,
  device_group_id,
  onClose,
}: Props) => {
  const classes = useStyles();
  const error = useSelector((state: RootState) => state.error);
  const deviceGroups = useSelector(
    (state: RootState) => state.device_group.data
  );
  const [editName, setEditName] = useState('');
  const [editRegex, setEditRegex] = useState('');
  const [editDeviceGroupId, setEditDeviceGroupId] = useState(0);

  const editDevice = async () => {
    let edit_device_group_id: number | null = editDeviceGroupId;
    if (edit_device_group_id === 0) {
      edit_device_group_id = null;
    }
    const response = await Axios.put<IDevice[]>(`/device/${id}`, {
      device_group_id: edit_device_group_id,
      name: editName,
      regex: editRegex,
    });
    if (response) {
      store.dispatch(deviceSlice.actions.setData(response.data));
      close();
    }
  };

  const close = () => {
    store.dispatch(errorSlice.actions.clear());

    onClose();
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    setEditName(name);
    setEditRegex(regex);
    setEditDeviceGroupId(device_group_id);
  }, [open, name, regex, device_group_id]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">デバイス 編集</DialogTitle>

      <DialogContent>
        <TextField
          value={editName}
          error={error.errors?.name ? true : false}
          helperText={error.errors?.name ?? ''}
          onChange={(e) => setEditName(e.target.value)}
          autoFocus
          margin="dense"
          id="name"
          label="名前"
          type="name"
          fullWidth
        />
        <TextField
          value={editRegex}
          error={error.errors?.regex ? true : false}
          helperText={error.errors?.regex ?? ''}
          onChange={(e) => setEditRegex(e.target.value)}
          margin="dense"
          id="regex"
          label="正規表現"
          type="regex"
          fullWidth
        />
        <FormControl
          className={classes.formControl}
          error={error.errors?.device_group_id ? true : false}
        >
          <InputLabel>デバイスグループ</InputLabel>
          <Select
            error={error.errors?.device_group_id ? true : false}
            id="device_group"
            value={editDeviceGroupId}
            onChange={(e) => {
              const value = e.target.value as number;
              setEditDeviceGroupId(value);
            }}
          >
            <MenuItem key={0} value={0}>
              none
            </MenuItem>
            {deviceGroups.map((deviceGroup) => {
              return (
                <MenuItem key={deviceGroup.id} value={deviceGroup.id}>
                  {deviceGroup.name}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{error.errors?.device_group_id ?? ''}</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          キャンセル
        </Button>
        <Button onClick={editDevice} color="primary">
          編集
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDeviceToolbar;
