import { Query } from 'material-table';

interface RequestQuery {
  pagination?: boolean;
  page?: number;
  per_page?: number;
  order_by?: string;
  sort?: 'asc' | 'desc';
  search?: string;
}

export const convertQuery = (query: Query<any>) => {
  const convertedQuery: RequestQuery = {
    pagination: true,
  };

  if (typeof query.page === 'number') {
    convertedQuery.page = query.page + 1;
  }
  if (typeof query.pageSize === 'number') {
    convertedQuery.per_page = query.pageSize;
  }
  if (query.search) {
    convertedQuery.search = query.search;
  }
  if (typeof query.orderBy?.field === 'string' && query.orderDirection) {
    convertedQuery.order_by = query.orderBy.field;
    convertedQuery.sort = query.orderDirection;
  }

  return convertedQuery;
};
