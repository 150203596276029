import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Color } from '@material-ui/lab/Alert';

interface INotification {
  open: boolean;
  content: string;
  severity?: Color;
}

const initialState: INotification = {
  open: false,
  content: 'string',
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    set: (
      state,
      action: PayloadAction<{ content: string; severity?: Color }>
    ) => {
      return {
        open: true,
        content: action.payload.content,
        severity: action.payload.severity,
      };
    },
    hide: (state) => {
      return { ...state, open: false };
    },
  },
});

export default slice;
