import React from 'react';
import { makeStyles } from '@material-ui/core';
import DeviceGroupsToolbar from 'components/organisms/pages/DeviceGroups/DeviceGroupsToolbar';
import DeviceGroupsTable from 'components/organisms/pages/DeviceGroups/DeviceGroupsTable';
import store from 'stores';
import { getDeviceGroups } from 'stores/slices/device_group';
import { check } from 'helpers/rbac';
import { Redirect } from 'react-router-dom';
import { AuthRoutes } from 'models/route';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const DeviceGroups = () => {
  const classes = useStyles();

  if (!check('manage-config')) {
    return (
      <Redirect
        to={{
          pathname: AuthRoutes.index,
        }}
      />
    );
  }

  // created
  store.dispatch(getDeviceGroups());

  return (
    <div className={classes.root}>
      <DeviceGroupsToolbar />
      <div className={classes.content}>
        <DeviceGroupsTable />
      </div>
    </div>
  );
};

export default DeviceGroups;
