import React from 'react';
import clsx from 'clsx';
import { makeStyles, Button } from '@material-ui/core';

import AddExcludedIpDialog from 'components/organisms/pages/ExcludedIps/AddExcludedIpDialog';
import useBooleanState from 'helpers/hooks/use_boolean_state';

interface Props {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const UsersToolbar = ({ className }: Props) => {
  const [dialog, open, close] = useBooleanState(false);
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <AddExcludedIpDialog open={dialog} onClose={close} />
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            open();
          }}
        >
          追加
        </Button>
      </div>
    </div>
  );
};

export default UsersToolbar;
