import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Button, Link } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import store, { RootState } from 'stores';
import { getLogAggregate } from 'stores/slices/log_aggregate';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

interface Props {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  datePicker: {
    marginRight: theme.spacing(1),
  },
}));

const LogAggregateToolbar = ({ className }: Props) => {
  const error = useSelector((state: RootState) => state.error);
  const logAggregate = useSelector(
    (state: RootState) => state.log_aggregate.data
  );

  const { id } = useParams();
  const classes = useStyles();
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  useEffect(() => {
    if (!logAggregate.from || !logAggregate.to) {
      return;
    }
    setFromDate(new Date(logAggregate.from));
    setToDate(new Date(logAggregate.to));
  }, [logAggregate]);

  const onButtonClicked = () => {
    if (!fromDate || !toDate) {
      return;
    }
    const from = dayjs(fromDate).format('YYYY-MM-DD');
    const to = dayjs(toDate).format('YYYY-MM-DD');

    store.dispatch(getLogAggregate(Number(id), { from, to }));
  };

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <Link to="/links" component={RouterLink}>
          <Button startIcon={<ArrowBack />}>戻る</Button>
        </Link>

        <span className={classes.spacer} />

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            error={error.errors?.from ? true : false}
            helperText={error.errors?.from ?? ''}
            className={classes.datePicker}
            margin="normal"
            disableToolbar
            variant="inline"
            format="yyyy/MM/dd"
            id="from"
            label="開始日"
            value={fromDate}
            onChange={(date: Date | null) => {
              setFromDate(date);
            }}
          />

          <KeyboardDatePicker
            error={error.errors?.to ? true : false}
            helperText={error.errors?.to ?? ''}
            className={classes.datePicker}
            margin="normal"
            disableToolbar
            variant="inline"
            format="yyyy/MM/dd"
            id="to"
            label="終了日"
            value={toDate}
            onChange={(date: Date | null) => {
              setToDate(date);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change to date',
            }}
          />
        </MuiPickersUtilsProvider>

        <Button
          disabled={!fromDate || !toDate}
          variant="contained"
          color="primary"
          onClick={() => {
            onButtonClicked();
          }}
        >
          集計
        </Button>
      </div>
    </div>
  );
};

export default LogAggregateToolbar;
