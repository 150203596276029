import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IIp } from 'models/ip';
import { AppThunk } from 'stores';
import Axios from 'axios';

interface IIpStore {
  data: IIp[];
}

const initialState: IIpStore = {
  data: [],
};

const slice = createSlice({
  name: 'excluded_ip',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<IIp[]>) => {
      return { ...state, data: action.payload };
    },
  },
});

export default slice;

export const getIps = (): AppThunk<Promise<boolean>> => async (dispatch) => {
  try {
    const response = await Axios.get<IIp[]>('/ip');
    dispatch(slice.actions.setData(response.data));

    return true;
  } catch {
    return false;
  }
};
