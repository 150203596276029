import React, { useState, FormEvent, useEffect } from 'react';
import {
  makeStyles,
  Container,
  CssBaseline,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import store, { RootState } from 'stores';
import Axios from 'axios';
import notificationSlice from 'stores/slices/notification';
import { IPasswordReset } from 'models/password_reset';
import useRouter from 'helpers/hooks/use_rooter';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginTop: theme.spacing(3),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ResetPasswordAuthenticate = () => {
  const classes = useStyles();
  const error = useSelector((state: RootState) => state.error);
  const router = useRouter();
  const query = queryString.parse(window.location.search);
  const [email, setEmail] = useState('');
  const [token] = useState(typeof query.token === 'string' ? query.token : '');
  const [password, setPassword] = useState('');

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const response = await Axios.patch('/user/reset-password', {
      email,
      token,
      password,
      password_confirmation: password,
    });

    if (response) {
      store.dispatch(
        notificationSlice.actions.set({
          content: 'Success!',
          severity: 'success',
        })
      );
      router.push('/');
    }
  };

  useEffect(() => {
    if (!token) {
      return;
    }
    (async () => {
      const response = await Axios.get<IPasswordReset>('/user/reset-password', {
        params: {
          token,
        },
      });
      if (response) {
        setEmail(response.data.email);
      }
    })();
  }, [token]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Typography className={classes.title} variant="h2">
          パスワードリセット
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            value={email}
            disabled
            onChange={(e) => setEmail(e.target.value)}
            error={error.errors?.email ? true : false}
            helperText={error.errors?.email ?? ''}
            margin="normal"
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
          />
          <TextField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={error.errors?.password ? true : false}
            helperText={error.errors?.password ?? ''}
            margin="normal"
            fullWidth
            name="password"
            label="パスワード"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            パスワードリセット
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default ResetPasswordAuthenticate;
