import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDeviceGroup } from 'models/device_group';
import { AppThunk } from 'stores';
import Axios from 'axios';

interface IDeviceGroupStore {
  data: IDeviceGroup[];
}

const initialState: IDeviceGroupStore = {
  data: [],
};

const slice = createSlice({
  name: 'device_store',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<IDeviceGroup[]>) => {
      return { ...state, data: action.payload };
    },
  },
});

export default slice;

export const getDeviceGroups = (): AppThunk<Promise<boolean>> => async (
  dispatch
) => {
  try {
    const response = await Axios.get<IDeviceGroup[]>('/device/group');
    dispatch(slice.actions.setData(response.data));

    return true;
  } catch {
    return false;
  }
};
